import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../redux";
import { useRoomContext } from "../../../domains/app/contexts/room.context";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { useLazyGetNectarsListQuery } from "../../../domains/nectar/endpoints/getNectarsList";
import { memorizeNectarsList, memorizeNectarsListRoom, setSwipeIndex } from "../../../domains/nectar/nectar.reducer";
import { NectarModel } from "../../../domains/nectar/nectar.types";
import styled from "styled-components";
import { displayToast } from "../../../components/app/AppToast";
import Loader from "../../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { gaEventViewItemListNectar } from "../../../tools/analytics/nectarAnalytics";
import { SupportedLanguagesEnum } from "../../../interfaces";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack, NavLang } from "../../../components/app/headers/layout";
import BottomNavbar from "../../../components/app/BottomNavbar";
import HomeContentFilters from "../../../components/home/layout/HomeContentFilters";
import FiltersDrawer from "../../../components/filters/FiltersDrawer";
import disableScroll from "disable-scroll";
import ItemList from "../../../components/app/ItemList";
import NectarCard from "../../../components/cards/NectarCard";

export default function HomeContentNectarsPage() {
  const dispatch = useAppDispatch();
  const { isInRoom } = useRoomContext();
  const { activeLang, t } = useLangContext();
  const [getNectarsList] = useLazyGetNectarsListQuery();
  const [isLoading, setLoading] = useState(true);
  const [nectars, setNectars] = useState<NectarModel[]>([]);
  const [total, setTotal] = useState(0);
  const [specialtyFilters, setSpecialtyFilters] = useState<[] | null>(null);
  const [isFilterDrawerVisible, setFilterDrawerVisible] = useState(false);

  useEffect(() => {
    if (specialtyFilters !== null) {
      loadNectars(true);
    }
  }, [specialtyFilters]);

  useEffect(() => {
    gaEventViewItemListNectar({
      nectars,
      listName: AnalyticsItemList.HOME,
    });
  }, [nectars]);

  useEffect(() => {
    scrollTo(0, 0);
    disableScroll.off();
    loadFilters();
    loadNectars(true);
  }, [activeLang]);

  async function loadFilters() {
    try {
      if (localStorage.getItem("homeListContentFilters")) {
        const { medical_specialties } = JSON.parse(
          localStorage.getItem("homeListContentFilters") as string
        );
        setSpecialtyFilters(medical_specialties);
      } else {
        setSpecialtyFilters([]);
      }
    } catch (error) {
      console.error("Couldn't load filters.", error);
    }
  }

  async function loadNectars(resetList = false) {
    try {
      const params: {
        limit: number;
        offset: number;
        language: SupportedLanguagesEnum;
        medicalSpecialties?: string;
      } = {
        limit: 10,
        offset: resetList ? 0 : nectars.length,
        language: activeLang,
      };

      if (specialtyFilters?.length) {
        params.medicalSpecialties = specialtyFilters.map((spe: { _id: string }) => spe._id).join(",");
      }

      await getNectarsList(params)
        .unwrap()
        .then(({ docs, total }) => {
          const newList = resetList ? docs : [...nectars, ...docs];
          setNectars(newList);
          setTotal(total);
          setLoading(false);
          dispatch(isInRoom
            ? memorizeNectarsListRoom({ list: newList, total })
            : memorizeNectarsList({ list: newList, total })
          );
        })
        .catch((error) => {
          throw error;
        })
    } catch (error) {
      console.error("Couldn't fetch content.", error);
      displayToast(t("error:default"));
    }
  }

  function handleChangeFilters(filters: { medical_specialties: [] }) {
    localStorage.setItem("homeListContentFilters", JSON.stringify(filters));
    setSpecialtyFilters(filters.medical_specialties);
  };

  return (
    <>
      <Wrapper>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack /></HeaderLinks>
          <HeaderTitle>{t("nectars.title")}</HeaderTitle>
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxAutoSafe>

        <HomeContentFilters
          active={!!specialtyFilters?.length}
          onClick={() => setFilterDrawerVisible(true)}
        />

        <div style={{ flex: 1 }}>
          {isLoading ? (
            <Loader loaderOnly />
          ) : !nectars.length ? (
            <NoResult>{t("No content")}</NoResult>
          ) : (
            <div className='page-content'>
              <InfiniteScroll
                dataLength={nectars.length}
                next={loadNectars}
                hasMore={nectars.length < total}
                loader={null}
              >
                <ItemList>
                  {nectars.map((nectar, index) => (
                    <NectarCard
                      key={nectar._id}
                      nectar={nectar}
                      analyticsListName={AnalyticsItemList.HOME}
                      onClick={() => {
                        dispatch(setSwipeIndex(index));
                      }}
                    />
                  ))}
                </ItemList>
              </InfiniteScroll>
            </div>
          )}
        </div>

        <BottomNavbar />
      </Wrapper>

      <FiltersDrawer
        showDrawer={isFilterDrawerVisible}
        onClose={() => setFilterDrawerVisible(false)}
        medical_specialties={specialtyFilters}
        handleFilters={(filters: any) => {
          handleChangeFilters(filters);
          setFilterDrawerVisible(false);
        }}
        hideTags
      />
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: rgba(238, 248, 34, 0.40);
`;

const NoResult = styled.div`
  padding: 8px 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 110%;
  color: #212121;
`;
