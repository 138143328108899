import { SupportedLanguagesEnum } from "../../interfaces";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import CustomSearchInput from "../CustomSearchInput";
import styled from "styled-components";

// NOTE: Convert to tsx.

export const SearchPageWrapper = styled.div`
  box-sizing: border-box;
  min-height: 100dvh;
  max-height: 100dvh;
  display: flex;
  flex-direction: column;
  
  .category-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 70%;
    letter-spacing: 0.02em;
    color: #212121;
  }
`;

export const SearchPageHeader = styled.div`
  .search-input-wrapper {
    padding: 8px 15px;
    touch-action: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  div.category-scroll {
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;

    .tab {
      flex: 0 0 auto;
      margin: 0px 10px;
      padding: 20px 0px;
      align-items: center;
      opacity: 0.3;
      transition: opacity ease-in-out 0.3s;

      &.selected {
        opacity: 1;
      }

      .tab-inner {
        display: flex;
        align-items: center;
        gap: 0 8px;
      }
    }
  }
`;

export const SearchPageContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export function SearchPageInput(props) {
  const { activeLang, t } = useLangContext();

  return (
    <CustomSearchInput
      placeholder={
        t("search:searchLanguage") +
        " " + ({
          [SupportedLanguagesEnum.EN]: t("common:lang:english"),
          [SupportedLanguagesEnum.FR]: t("common:lang:french"),
          [SupportedLanguagesEnum.IT]: t("common:lang:italian"),
          [SupportedLanguagesEnum.ES]: t("common:lang:spanish"),
        }[activeLang] ?? t("common:lang:english")).toLocaleUpperCase()
      }
      cancelText={t("common:action.back")}
      value={props.value}
      {...props}
    />
  );
}

export function SearchResultsSection({ children }) {
  return (
    <ResultsContainer>
      <div className="result-section-inner">
        {children}
      </div>
    </ResultsContainer>
  );
}

const ResultsContainer = styled.div`
  &:last-of-type {
    .result-section-inner {
      border-bottom: none;
    }
  }

  .result-section-inner {
    padding: 0 20px 40px;
    border-bottom: 1px solid #90a4ae;
  }

  &.hide {
    display: none;
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #212121;
  }
`;

export const SearchResultsListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const NoResultText = styled.p`
  color: #212121;
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  margin-top: 50px;
`;

export const ViewButton = styled.button`
  outline: none;
  box-shadow: none;
  background: #ffffff;
  color: #212121;
  border: 1px solid #d2dce2;
  border-radius: 100px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.02em;
  padding: 12px 16px;
  text-transform: capitalize;
  margin: 35px auto 0px;
  display: block;
`;
