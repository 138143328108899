import { Component } from "react";
import { getItemTranslation } from "../../../domains/app/utils/getItemTranslation";
import { t } from "i18next";
import { renderDate } from "../../../domains/app/utils/renderDate";
import { connect } from "react-redux";
import styled from "styled-components";
import CustomIcon from "../../../components/CustomIcon";
import GamificationBadge from "../../../components/gamification/GamificationBadge";
import Loader from "../../../components/Loader";
import i18n from "../../../config/i18n";
import { userApi } from "../../../redux/user/user.service";
import { displayToast } from "../../../components/app/AppToast";
import { getUserById } from "../../../domains/user/endpoints/getUserById";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderLogo, NavBack } from "../../../components/app/headers/layout";
import { iosPlaySound } from "../../../tools/ios";
import { SoundEffect, SupportedLanguagesEnum } from "../../../interfaces";

const ESTIMED_ARTICLE_READ_TIME = 31.03;

class ProfileGamification extends Component {
  state = {
    profile: undefined,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);

    const isMe = this.props.location.pathname.includes("profile");
    const userID = this.props.match.params.user_id ?? undefined;

    if (isMe) {
      await this.props.getUser();

      if (!this.props.user.playlist) await this.props.getPlaylists();

      this.setState({ profile: this.props.user.user });
    } else if (userID) {
      /** If shared page */
      getUserById(userID).then((profile) => this.setState({ profile }));
    } else {
      /** Redirect to home page if user not found */
      this.props.history.replace("/discovery");
      console.log("error: user not found");
    }
  }

  formatMinutesToTime(time) {
    var mins_num = parseFloat(time, 10);
    var hours = Math.floor(mins_num / 60);
    var minutes = Math.floor(mins_num - (hours * 3600) / 60);
    var seconds = Math.floor(mins_num * 60 - hours * 3600 - minutes * 60);

    // Appends 0 when unit is less than 10
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return (
      <div className='render-saved-time'>
        {hours}
        <span>hours</span> {minutes}
        <span>minutes</span>
      </div>
    );
  }

  getProfileMetrics() {
    const { articleViews, articleShares, contentViews, contentShares } =
      this.state.profile.metrics;

    const totalSaves = this.props.user.playlists
      ? this.props.user.playlists.reduce(
        (total, p) => total + p.playlist.length,
        0
      )
      : 0;

    const videosSaved = Array.from(
      new Set(
        this.props.user.playlists.flatMap((playlist) => [
          ...playlist.playlistContent,
        ])
      )
    );

    return [
      {
        metric: t("Total Articles Read"),
        count: articleViews,
        icon: "metricTotalArticlesRead",
      },
      {
        metric: t("Total Articles Saved"),
        count: totalSaves ?? 0,
        icon: "metricTotalArticlesSaved",
      },
      {
        metric: t("Total Articles Shared"),
        count: articleShares,
        icon: "metricTotalArticlesShared",
      },
      {
        metric: t("Total Videos Watched"),
        count: contentViews,
        icon: "metricTotalVideosWatched",
      },
      {
        metric: t("Total Videos Saved"),
        count: videosSaved.length ?? 0,
        icon: "metricTotalVideosSaved",
      },
      {
        metric: t("Total Videos Shared"),
        count: contentShares,
        icon: "metricTotalVideosShared",
      },
      {
        metric: t("of Total Reading Time Saved"),
        count: this.formatMinutesToTime(
          articleViews * ESTIMED_ARTICLE_READ_TIME
        ),
        icon: "metricTotalReadingTimeSaved",
      },
    ];
  }

  getProfileBadges() {
    const { longestStreak } = this.state.profile.metrics;
    let totalBagde = [];
    for (let i = 5; i <= 90; i += 5) {
      totalBagde.push(i);
    }

    return totalBagde.map((currentIndex) => (
      <GamificationBadge
        key={currentIndex + "totalbadge"}
        count={currentIndex}
        disabled={longestStreak < currentIndex}
      />
    ));
  }

  renderNoticeText() {
    const { currentStreak } = this.state.profile.metrics;

    if (currentStreak === 0) return t("profile.game.empty_streak");
    else if (currentStreak > 90) return t("profile.game.congrats_streak");
    else if (currentStreak < 5)
      return `${5 - currentStreak} ${5 - currentStreak === 1 ? t("more day") : t("more days")
        } ${t("profile.game.notice")}`;
    else
      return (
        5 -
        (parseInt(currentStreak / 5) % 5) +
        " " +
        t("more days") +
        " " +
        t("profile.game.notice")
      );
  }

  handleShareGameProfile() {
    const { profile } = this.state;
    const location = window.location.protocol + "//" + window.location.host;

    const shareText =
      i18n.resolvedLanguage === SupportedLanguagesEnum.FR
        ? "Découvre mon parcours et mes stats d'apprentissage sur Juisci !"
        : "Check out my learning activity and stats on Juisci! ";

    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: shareText,
          url: `${location}/user/game/activity/${profile?.uid}`,
        })
        .then(() => {
          iosPlaySound(SoundEffect.SHARE);
          displayToast(
            t("toast.success.profile_shared_me"),
            "success"
          );
        })
        .catch(() => { });
    } else {
      displayToast(t("toast.fail.browser"));
    }
  }

  render() {
    const isMe = this.props.location.pathname.includes("profile");
    const { profile } = this.state;

    /** Setting longestStreak start date */
    let longestStreakStartDate = new Date(
      profile?.metrics?.longestStreakEndDate
    );
    longestStreakStartDate.setDate(
      longestStreakStartDate.getDate() - profile?.metrics?.longestStreak
    );
    /**  */

    if (!profile) return <Loader />;
    else
      return (
        <StyledWrapper>
          <HeaderBoxAutoSafe>
            <HeaderLinks><NavBack onClick={() =>
              isMe
                ? this.props.history.goBack()
                : this.props.history.replace("/profile/user/" + profile.uid)
            } /></HeaderLinks>
            <HeaderLogo />
            <HeaderLinks />
          </HeaderBoxAutoSafe>

          <div className='page-header'>
            <div className='streak-grid'>
              <div />
              <div className='profile-infos'>
                {profile.firstname} <b>{profile.lastname}</b>
                {profile.profession && (
                  <div>
                    #{getItemTranslation(profile.profession)}
                    #{getItemTranslation(profile?.main_specialty)}
                  </div>
                )}
              </div>
              {isMe ? (
                <CustomIcon
                  onClick={this.handleShareGameProfile.bind(this)}
                  iconName='share-company'
                />
              ) : (
                <div />
              )}
            </div>

            <h5>{t("current streak")}</h5>

            <div className='fade-numbers'>
              <h1>
                {profile.metrics.currentStreak - 1 > 0
                  ? `${profile.metrics.currentStreak - 1}`.padStart(2, "0")
                  : ""}
              </h1>
              <h1>{`${profile.metrics.currentStreak}`.padStart(2, "0")}</h1>
              <h1>
                {profile.metrics.currentStreak < 90
                  ? `${profile.metrics.currentStreak + 1}`.padStart(2, "0")
                  : ""}
              </h1>
            </div>
            <h3>{t("days")}</h3>
            {isMe ? (
              <>
                <h4>{t("profile.game.h4")}</h4>
                <div className='notice-text'>{this.renderNoticeText()}</div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className='page-content'>
            <div className='streak-card'>
              <CustomIcon iconName='medal' />
              <div>
                <h5>
                  {isMe
                    ? t("profile.game.longest_streak")
                    : t("profile.game.user.longest_streak")}
                </h5>
                <div className='date-range'>
                  {t("profile.game.from")}{" "}
                  <time>{renderDate(longestStreakStartDate.toISOString(), true)}</time>
                  {" "}{t("profile.game.to")}{" "}
                  <time>{renderDate(profile.metrics.longestStreakEndDate, true)}</time>
                </div>
              </div>
              <h4>
                {`${profile.metrics.longestStreak}`.padStart(2, "0")}
                <span>days</span>
              </h4>
            </div>
            <hr />
            <div>
              <div className='section-title'>
                <CustomIcon iconName='tubes_bar' />
                <h5>Stats</h5>
              </div>
              {this.getProfileMetrics().map((item, index) => (
                <div
                  className='metric-row'
                  key={item.metric + index * item.count}
                >
                  <CustomIcon iconName={item.icon} />
                  <div className='metric-text'>
                    {item.count} <span className='subtitle'>{item.metric}</span>
                  </div>
                </div>
              ))}
            </div>
            <hr />
            <div>
              <div className='section-title'>
                <CustomIcon iconName='brain' />
                <h5>{t("current streak")}</h5>
              </div>
            </div>
            <div className='badges-row'>{this.getProfileBadges()}</div>
          </div>
        </StyledWrapper>
      );
  }
}

function mapState(state) {
  const user = state.user;
  return {
    user,
  };
}

const mapDispatch = {
  getUser: userApi.endpoints.getUser.initiate,
  getPlaylists: userApi.endpoints.getPlaylists.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(ProfileGamification);

const StyledWrapper = styled.div`
  background-color: #fff;
  min-height: 100vh;
  box-sizing: border-box;
      
  hr {
    margin: 30px 0;
  }

  .page-header {
    padding: 40px 0px;
    background: linear-gradient(
      360deg,
      #ffc408 0%,
      #ff8800 51.56%,
      #ff7fbd 100%
    );

    & > * {
      text-align: center;
      font-family: "Inter";
      color: #fff;
    }

    .profile-infos {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 100%;
      text-align: center;
      color: #212121;

      b {
        font-weight: 700;
      }

      & :nth-child(2) {
        display: block;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.02em;
      }
    }

    .streak-grid {
      padding: 0 22px;
      display: grid;
      grid-template-columns: 32px auto 32px;
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      justify-content: space-between;
      justify-items: center;
      align-items: center;

      h5 {
        width: 100%;
      }
    }

    .fade-numbers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 -40px;
      overflow: hidden;
      transform: translateX(-5px);

      & :nth-child(1),
      & :nth-child(3) {
        opacity: 0.1;
      }

      h1 {
        width: 120px;
      }
    }

    h1,
    h3 {
      margin: 0;
    }

    h1 {
      font-weight: 700;
      font-size: 100px;
      line-height: 115%;
      margin: 0;
    }

    h3 {
      font-weight: 700;
      font-size: 31px;
      line-height: 100%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    h4 {
      font-style: italic;
      font-weight: 600;
      font-size: 17px;
      line-height: 115%;
      text-align: center;
      opacity: 0.6;
      margin: 20px 60px 30px;
    }

    h5 {
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 0.06em;
      text-transform: uppercase;
    }

    .notice-text {
      font-weight: 700;
      font-size: 12px;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #212121;
      padding: 0 40px;
      /* color: rgba(33, 33, 33, 0.6); */
    }
  }

  .page-content {
    padding: 40px 20px;

    .streak-card {
      padding: 24px 21px;
      background-color: #fff;
      box-shadow: 2px 2px 10px rgba(33, 33, 33, 0.1);
      border-radius: 20px;
      position: relative;
      /** Flex */
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 18px;

      & > * {
        font-family: "Inter";
      }

      .--custom-icon {
        position: absolute;
        top: -15px;
        left: 15px;
      }

      h5 {
        font-style: italic;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 17px;
        line-height: 115%;
        color: #212121;
        margin: 0 0 5px;
      }

      .date-range {
        font-weight: 400;
        font-size: 11px;
        line-height: 115%;
        letter-spacing: 0.04em;
        color: #617985;
      }

      h4 {
        font-weight: 700;
        font-size: 40px;
        line-height: 115%;
        text-align: center;
        text-transform: uppercase;
        color: #ce0868;
        margin: 0;
        transform: translateY(-5px);

        & :nth-child(1) {
          display: block;
          font-size: 14px;
          line-height: 100%;
        }
      }
    }

    .section-title {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 42px;
      /** Font style */
      h5 {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 120%;
        /* text-transform: capitalize; */
        color: #212121;
        margin: 0px;
      }
    }

    .metric-row {
      display: flex;
      align-items: center;
      gap: 15px;
      margin: 24px 0;

      .metric-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 115%;
        text-align: left;
        letter-spacing: 0.04em;
        color: #212121;

        .subtitle {
          display: block;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 130%;
          letter-spacing: 0.02em;
          /* text-transform: capitalize; */
          color: #000000;
        }

        .render-saved-time {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 115%;
          text-align: left;
          letter-spacing: 0.04em;
          color: #212121;

          span {
            font-size: 16px;
          }
        }
      }
    }

    .badges-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 12px;
      grid-row-gap: 12px;
      justify-items: center;
      justify-content: space-between;
    }
  }
`;
