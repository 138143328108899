import { useState } from "react";
import { Link } from "react-router-dom";
import { deleteCurrentUser } from "../../domains/user/endpoints/deleteCurrentUser";
import { logoutUserFromApp } from "../../tools/utils";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderTitle,
  NavBack,
} from "../../components/app/headers/layout";
import ProfileInfo from "../../components/settings/ProfileInfo";
import SettingButton from "../../components/settings/SettingButton";
import SettingButtonSwitch from "../../components/settings/SettingButtonSwitch";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";
import styled from "styled-components";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { SupportedLanguagesEnum } from "../../interfaces";

export default function SettingsEditAccountPage() {
  const { t } = useLangContext();
  const [disableSound, setDisableSound] = useState(
    localStorage.getItem("disableSound") === "true"
  );
  const [disableHaptic, setDisableHaptic] = useState(
    localStorage.getItem("disableHaptic") === "true"
  );
  const [isDeleteAccountModalVisible, setDeleteAccountModalVisible] =
    useState(false);
  const [isDeletingAccount, setDeletingAccount] = useState(false);

  function handleUpdateSound() {
    localStorage.setItem("disableSound", JSON.stringify(!disableSound));
    setDisableSound(!disableSound);
  }

  function handleUpdateHaptic() {
    localStorage.setItem("disableHaptic", JSON.stringify(!disableHaptic));
    setDisableHaptic(!disableHaptic);
  }

  async function handleDeleteAccount() {
    try {
      setDeletingAccount(true);
      await deleteCurrentUser();
      displayToast(t("Your account has been successfully deleted"));

      logoutUserFromApp();
      setTimeout(() => {
        window.location.replace("/signup/pre");
      }, 2000);
    } catch (error) {
      setDeletingAccount(false);
      throw error;
    }
  }

  return (
    <>
      <Container>
        <HeaderBoxAutoSafe>
          <HeaderLinks>
            <NavBack />
          </HeaderLinks>
          <HeaderTitle>{t("settings.settings")}</HeaderTitle>
          <HeaderLinks />
        </HeaderBoxAutoSafe>

        <TopContainer>
          <PageTitle>{t("Profile")}</PageTitle>
          <Link to="/settings/edit/profile">
            <ProfileInfo />
          </Link>
        </TopContainer>

        <BottomContainer>
          <SettingButtonSwitch
            iconName="volume"
            iconScale={1.1}
            checked={!disableSound}
            onClick={handleUpdateSound}
          >
            {t("settings.enable_sound")}
          </SettingButtonSwitch>

          <SettingButtonSwitch
            iconName="haptic"
            iconScale={1.1}
            checked={!disableHaptic}
            onClick={handleUpdateHaptic}
          >
            {t("settings.enable_haptic")}
          </SettingButtonSwitch>

          <div style={{ marginTop: "auto" }}>
            <SettingButton
              iconName="delete_account"
              onClick={() => setDeleteAccountModalVisible(true)}
            >
              {t("profile.delete")}
            </SettingButton>
          </div>
        </BottomContainer>
      </Container>

      <DeleteAccountModal
        isVisible={isDeleteAccountModalVisible}
        onClose={() => setDeleteAccountModalVisible(false)}
        onDeleteAccount={handleDeleteAccount}
        isDeletingAccount={isDeletingAccount}
      />
    </>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  height: 100dvh;
  padding-bottom: 72px;
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PageTitle = styled.div`
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  letter-spacing: -0.025em;
`;

const BottomContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

function DeleteAccountModal({
  isVisible,
  onClose,
  onDeleteAccount,
  isDeletingAccount,
}: {
  isVisible: boolean;
  onClose: () => void;
  onDeleteAccount: () => void;
  isDeletingAccount: boolean;
}) {
  const { activeLang } = useLangContext();

  function handleOutsideClick(e: React.MouseEvent<HTMLDivElement>) {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }

  if (!isVisible) return null;

  return (
    <DeleteAccountModalStyled onClick={handleOutsideClick}>
      <div className="modal">
        {/* NOTE: Temporary until translation is added to locale files */}
        {activeLang === SupportedLanguagesEnum.EN ? (
          <>
            <h2>Second bite at the cherry 🍒</h2>
            <h3>Do you really want to permanently delete your account?</h3>
          </>
        ) : (
          <>
            <h2>Dernière chance de presser le citron 🍋</h2>
            <h3>
              Voulez-vous vraiment supprimer définitivement votre compte ?
            </h3>
          </>
        )}
        <button className="cancel-button" onClick={onClose}>
          {t("common:action.cancel")}
        </button>
        <button
          className={`yes-button ${isDeletingAccount ? "loading" : ""}`}
          onClick={onDeleteAccount}
        >
          {t("common:yes")}
        </button>
      </div>
    </DeleteAccountModalStyled>
  );
}

const DeleteAccountModalStyled = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    background: white;
    border-radius: 12px;
    padding: 24px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  h2 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin: 0 0 5px 0;
  }

  h3 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin: 0 0 20px 0;
  }

  button {
    width: 60%;
    padding: 16px;
    border: none;
    border-radius: 25px;
    color: #212121;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
  }

  .cancel-button {
    background-color: #ffc107;
    color: black;
    margin-bottom: 10px;
  }

  .yes-button {
    background-color: #e0e0e0;
    color: black;
  }
`;
