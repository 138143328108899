import { UserModel, UserRoomModel, UserRoomType } from "../user.types";
import { getRoomById } from "../../room/endpoints/getRoomById";

export async function getUserStartingPage(user: UserModel): Promise<string | null> {
  try {
    if (user.rooms.length > 0) {
      // NOTE: There seems to be corrupted data on user rooms (null).
      const roomId = user.rooms.filter((roomId:string) => !!roomId)[0];
      const roomData: UserRoomModel = await getRoomById(roomId);
      if (roomData.type === UserRoomType.AUDIO) {
        return `/room/audio/home/${roomId}`;
      } else {
        return `/room/discovery/${roomId}`;
      }
    }

    return null;
  } catch (error) {
    console.error("Couldn't get user starting page.", error);
    return null;
  }
}
