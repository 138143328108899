import { useHistory } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { BottomSheet } from "react-spring-bottom-sheet";
import { SheetWrapper } from "../CustomSheet";
import { PanelContainer, PanelRow } from "../app/panels/layout";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-circle-yellow.svg";

export default function RoomOptionsPanel({
  roomId,
  isVisible,
  onClose,
}: {
  roomId: string;
  isVisible: boolean;
  onClose: Function;
}) {
  const history = useHistory();
  const { t } = useLangContext();

  return (
    <BottomSheet
      expandOnContentDrag
      open={isVisible}
      onDismiss={() => {
        setTimeout(() => onClose(), 100);
      }}
    >
      <SheetWrapper style={{ height: window.innerHeight * 0.3 }}>
        <PanelContainer>
          {/* TODO: QR Code page */}
          {/* <PanelRow onClick={() => null}>
              <CustomIcon iconName='share-alt' color='#212121' />
              <div className="text">
                <span className="label">{t("Share room")}</span>
                <span className="description">{t("Share this room to other people")}</span>
              </div>
            </PanelRow> */}
          <PanelRow onClick={() => history.push(`/room/terms-privacy/${roomId}`)}>
            <InfoIcon />
            <div className="text">
              <span className="label">{t("room:panel.information.label")}</span>
              <span className="description">{t("room:panel.information.description")}</span>
            </div>
          </PanelRow>
        </PanelContainer>
      </SheetWrapper>
    </BottomSheet>
  );
}
