import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { useLazyGetNectarsListQuery } from "../../../domains/nectar/endpoints/getNectarsList";
import {
  memorizeNectarsList,
  setSwipeIndex,
} from "../../../domains/nectar/nectar.reducer";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { gaEventSelectItemNectar } from "../../../tools/analytics/nectarAnalytics";
import NectarScroller from "../../app/NectarScroller";

export default function HomeNectarScroller() {
  const dispatch = useAppDispatch();
  const { list, total } = useAppSelector((state) => state.nectars.cache.discovery);
  const { activeLang } = useLangContext();
  const [getNectarsList, { isLoading, isFetching }] =
    useLazyGetNectarsListQuery();

  useEffect(() => {
    fetchNectars(true);
  }, [activeLang]);

  async function fetchNectars(resetList = false) {
    await getNectarsList({
      limit: 50,
      offset: resetList ? 0 : list.length,
      language: activeLang,
    })
      .unwrap()
      .then(({ docs, total }) => {
        const newList = resetList ? docs : [...list, ...docs];
        dispatch(memorizeNectarsList({ list: newList, total }));
      })
      .catch((error) => {
        console.error("Couldn't get nectars.", error);
      });
  }

  function handleScrollLoadMore() {
    if (list.length < total && !isFetching) {
      fetchNectars();
    }
  }

  return (
    <NectarScroller
      isLoading={isLoading}
      nectars={list}
      seeAllPath='/discovery/nectars'
      allLoaded={list.length === total}
      onNectarClick={(index: number) => {
        const nectar = list[index];
        gaEventSelectItemNectar({
          nectar,
          listName: AnalyticsItemList.HOME,
        });
        dispatch(setSwipeIndex(index));
      }}
      onScrollNearEnd={handleScrollLoadMore}
    />
  );
}
