import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LOGIN_SIGNUP_LANGUAGES, PUBLIC_INAPP_LANGUAGES } from "../../config/i18n";
import { useRoomContext } from "../../domains/app/contexts/room.context";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { HapticEffect, SupportedLanguagesEnum } from "../../interfaces";
import { iosGenerateHaptic } from "../../tools/ios";
import { Drawer } from "@mui/material";
import CustomIcon from "../CustomIcon";
import CustomRadio from "../CustomRadio";
import styled from "styled-components";
import AppButton from "./AppButton";

export default function LanguagePanel({
  isVisible,
  onClose,
  displayConfirmButton,
}: {
  isVisible: boolean;
  onClose: Function;
  displayConfirmButton?: boolean;
}) {
  const location = useLocation();
  const {
    isInRoom,
    allowedLanguages: roomAllowedLanguages,
  } = useRoomContext();
  const { activeLang, changeActiveLang, t } = useLangContext();
  const isOnLoginSignup = ["/login", "/signup"].some((pathRoot: string) => location.pathname.startsWith(pathRoot));
  const availableLanguages = isInRoom
    ? roomAllowedLanguages
    : isOnLoginSignup ? LOGIN_SIGNUP_LANGUAGES : PUBLIC_INAPP_LANGUAGES;

  useEffect(() => {
    if (activeLang && !availableLanguages.includes(activeLang)) {
      changeActiveLang(availableLanguages[0]);
    }
  }, []);

  function handleChange(lang: SupportedLanguagesEnum) {
    changeActiveLang(lang);
    iosGenerateHaptic(HapticEffect.SELECTION);
  }

  return (
    <PanelContainer
      anchor="left"
      open={isVisible}
      onClose={() => onClose && onClose()}
    >
      <div className="header">
        <h5>{t("common:lang.selectLanguage")}</h5>
        {!displayConfirmButton && (
          <CustomIcon
            iconName="close_alt"
            color="#FFFFFF"
            color2="#212121"
            onClick={() => onClose && onClose()}
          />
        )}
      </div>
      {Object.entries({
        [SupportedLanguagesEnum.EN]: t("common:lang.english"),
        [SupportedLanguagesEnum.FR]: t("common:lang.french"),
        [SupportedLanguagesEnum.ES]: t("common:lang.spanish"),
        [SupportedLanguagesEnum.IT]: t("common:lang.italian"),
      })
        .map(([lang, text], index) => {
          return availableLanguages.includes(lang as SupportedLanguagesEnum) ? (
            <CustomRadio
              key={index}
              selectColor='#FFC408'
              textStyle={{ color: "#fff", fontWeight: 700 }}
              onChange={() => handleChange(lang as SupportedLanguagesEnum)}
              checked={lang === activeLang}
              title={text}
            />
          ) : null;
        })
      }
      {!!displayConfirmButton && (
        <AppButton
          style={{ marginTop: "auto" }}
          onClick={() => onClose && onClose()}
        >
          {t("common:action.confirm")}
        </AppButton>
      )}
    </PanelContainer>
  );
}

const PanelContainer = styled(Drawer)`
  width: 100%;

  .MuiDrawer-paper {
    background: #ce0868;
    width: 100%;
    padding: 71px 24px;
    box-sizing: border-box;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #fff;
    padding-bottom: 27px;

    h5 {
      font-family: "Inter";
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      letter-spacing: -0.00011em;
      color: #ffffff;
      margin: 0;
    }
  }
`;
