import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { getOrganisationFromInviteCode } from "../../domains/auth/endpoints/getOrganisationFromInviteCode";
import { signupEmailPassword } from "../../services/firebase";
import { gaEventStartSignUp, gaEventViewSignUp } from "../../tools/analytics/authAnalytics";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderLogo, NavLang } from "../../components/app/headers/layout";
import StepBars from "../../components/app/StepBars";
import Loader from "../../components/Loader";
import LanguagePanel from "../../components/app/LanguagePanel";
import { CustomButton } from "../../components/global";
import { CustomInput } from "../../components/CustomInput";
import CustomDatePicker from "../../components/CustomDatePicker";
import ComboBox from "../../components/skeleton/ComboBox";
import CustomCheckbox from "../../components/CustomCheckbox";
import styled from "styled-components";
import moment from "moment";
import disableScroll from "disable-scroll";
import {
  POPULAR_COUNTRY_CODES,
  getCountriesList,
  getCountriesListSortedByPopularity,
} from "../../domains/app/utils/countries";
import { displayToast } from "../../components/app/AppToast";
import { Trans } from "react-i18next";
import { ReactComponent as EyeOpen } from "../../assets/icons/eye-open.svg";
import { ReactComponent as EyeClosed } from "../../assets/icons/eye-closed.svg";

const EXAMPLE_EMAIL = "example@juisci.com";

export default function SignupPage() {
  const history = useHistory();
  const location = useLocation<{ email: string }>();
  const { activeLang, t } = useLangContext();
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isLangSelected, setLangSelected] = useState(false);
  const [hasSignupStarted, setSignupStarted] = useState(true);
  const [organisation, setOrganisation] = useState<{
    uid: string;
    name: string;
    logo: { url: string };
  } | null>(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isLegacyChecked, setLegacyChecked] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const inviteCode = searchParams.get("inviteCode");
  const countryOptions = getCountriesListSortedByPopularity(activeLang);

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off();
        window.scrollTo(0, 0);

        const isAuthentified = await isUserAuthentified();
        if (isAuthentified) return history.replace("/profile");

        restoreCachedState();
        const organisation = await checkOrganisation();
        if (organisation) setOrganisation(organisation);

        setEmail(
          location?.state?.email
            ? location?.state?.email
            : email
              ? email
              : EXAMPLE_EMAIL
        );

        setLoading(false);
        gaEventViewSignUp();
      } catch (error) {
        console.error("Couldn't mount signup page.", error);
        displayToast(t("error:default"));
        history.replace("/login");
      }
    })();
  }, []);

  function restoreCachedState() {
    const signupState = localStorage.getItem("_signup_state");
    if (signupState) {
      const parsed = JSON.parse(localStorage.getItem("_signup_state") ?? "");
      setEmail(parsed.email);
      setFirstName(parsed.firstName);
      setLastName(parsed.lastName);
      setBirthDate(parsed.birthDate);
      setCountry(parsed.country);
      setPassword(parsed.password);
      setConfirmPassword(parsed.confirmPassword);
      setLangSelected(parsed.isLangSelected);
      localStorage.removeItem("_signup_state");
    }
  }

  function storeStateToCache() {
    localStorage.setItem(
      "_signup_state",
      JSON.stringify({
        firstName,
        lastName,
        birthDate,
        email,
        country,
        password,
        confirmPassword,
        isLangSelected,
      })
    );
  }

  async function checkOrganisation() {
    if (inviteCode) {
      const { organisation } = await getOrganisationFromInviteCode(inviteCode);
      return organisation;
    }
    return null;
  }

  async function handleSubmit(e: SyntheticEvent) {
    try {
      e.preventDefault();
      setSaving(true);

      if (
        [firstName, lastName, email, country, password, confirmPassword].some(
          (field) => !field.length
        )
      ) {
        displayToast(t("Please fill in all the fields"));
        setSaving(false);
        return;
      }

      if (!getCountriesList()[country]) {
        displayToast(t("common:form.error.country"));
        setSaving(false);
        return;
      }

      if (password !== confirmPassword) {
        displayToast(t("common:form.error.passwords"));
        setSaving(false);
        return;
      }

      if (password.length < 6) {
        displayToast(t("common:form.error.passwordLength"));
        setSaving(false);
        return;
      }

      if (!isLegacyChecked && !!inviteCode) {
        displayToast(t("common:form.error.legacy"));
        setSaving(false);
        return;
      }

      const data: any = {
        firstname: firstName,
        lastname: lastName,
        birthDate: new Date(birthDate),
        country,
      };

      if (inviteCode) {
        data.invitationCode = inviteCode;
      }

      const res = await signupEmailPassword(email, password, data);
      if (!res) {
        throw new Error(
          "Error creating account with signupEmailPassword firebase method"
        );
      }
    } catch (error) {
      console.error("Couldn't register.", error);
      displayToast(t("error:default"));
      setSaving(false);
    }
  }

  if (isLoading) return <Loader />;

  if (!isLangSelected) {
    return (
      <LanguagePanel
        isVisible={!isLangSelected}
        onClose={() => setLangSelected(true)}
        displayConfirmButton={true}
      />
    );
  }

  return (
    <Wrapper>
      <HeaderBoxAutoSafe>
        <HeaderLinks />
        <HeaderLogo />
        <HeaderLinks><NavLang /></HeaderLinks>
      </HeaderBoxAutoSafe>

      <Container>
        <StepBars
          maxSteps={4}
          currentStep={1}
          style={{
            container: { padding: "0 21px" },
          }}
        />

        <Heading>
          {organisation && (
            <img
              className='organisation-logo'
              src={organisation.logo?.url}
              alt={organisation.name}
            />
          )}

          <h1>{t("signup:title")}</h1>
          <span className='subtitle'>
            {t("common:fillInInformation")}
          </span>
        </Heading>

        <Form onSubmit={handleSubmit}>
          <CustomInput
            type='email'
            placeholder={t("common:form.field.email")}
            value={email}
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            required
            InputProps={{
              style: {
                color: email === EXAMPLE_EMAIL ? "#81939c" : "#212121",
              },
              onFocus: () => {
                if (email === EXAMPLE_EMAIL) setEmail("");
              },
            }}
          />

          <CustomInput
            placeholder={t("common:form.field.firstname")}
            value={firstName}
            onInput={(e: ChangeEvent<HTMLInputElement>) => {
              setFirstName(e.target.value);
              if (!hasSignupStarted) {
                setSignupStarted(true);
                gaEventStartSignUp();
              }
            }}
            required
          />

          <CustomInput
            placeholder={t("common:form.field.lastname")}
            value={lastName}
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              setLastName(e.target.value)
            }
            required
          />

          <CustomDatePicker
            defaultValue={birthDate}
            onChange={(date: Date) => {
              setBirthDate(moment(date).format("YYYY-MM-DD"));
            }}
          />

          <ComboBox
            placeholder={t("common:form.field.country")}
            options={countryOptions}
            value={country}
            groupBy={(option: { value: string }) =>
              POPULAR_COUNTRY_CODES.includes(option.value)
                ? t("Most selected")
                : t("Other countries")
            }
            onChange={(country: { value: string }) =>
              !!country && setCountry(country.value)
            }
            required
          />

          <CustomInput
            type={isPasswordVisible ? "text" : "password"}
            placeholder={t("common:form.field.password")}
            value={password}
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
            required
            suffix={!isPasswordVisible ? <EyeOpen /> : <EyeClosed />}
            suffixClick={() => setPasswordVisible(!isPasswordVisible)}
          />

          <CustomInput
            type={isConfirmPasswordVisible ? "text" : "password"}
            placeholder={t("common:form.field.confirmPassword")}
            value={confirmPassword}
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              setConfirmPassword(e.target.value)
            }
            required
            suffix={!isConfirmPasswordVisible ? <EyeOpen /> : <EyeClosed />}
            suffixClick={() =>
              setConfirmPasswordVisible(!isConfirmPasswordVisible)
            }
          />

          {!!organisation && (
            <div>
              <CustomCheckbox
                checked={isLegacyChecked}
                title={
                  <Credits>
                    <Trans
                      i18nKey="signup:disclaimer"
                      className="credits"
                      components={{
                        b: <b />,
                        termsLink: (
                          <Link
                            to={{
                              pathname: "/terms",
                              search: "?version=biocodex",
                              state: { isPush: true },
                            }}
                            onClick={storeStateToCache}
                          ></Link>
                        ),
                        policyLink: (
                          <Link
                            to={{
                              pathname: "/policy",
                              search: "?version=biocodex",
                              state: { isPush: true },
                            }}
                            onClick={storeStateToCache}
                          ></Link>
                        ),
                      }}
                    />
                  </Credits>
                }
                onChange={() => setLegacyChecked(!isLegacyChecked)}
              />
            </div>
          )}

          <CustomButton
            type='submit'
            className={isSaving ? "loading" : ""}
            disabled={isSaving}
          >
            {t("common:action.next")}
          </CustomButton>
        </Form>

        <LoginPrompt>
          {t("signup:alreadyOnJuisci")}{" "}
          <Link to='/login'>
            <span>{t("signup:action.login")}</span>
          </Link>
        </LoginPrompt>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  background: #ecf0f5;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  flex: 1;
  padding: 16px 0 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.div`
  margin: 24px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .organisation-logo {
    object-fit: contain;
    width: 150px;
    max-height: 48px;
    margin: 21px 0;
    padding: 16px;
    border-radius: 15px;
    background: white;
  }

  h1 {
    margin: 0;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    letter-spacing: -0.025em;
    text-align: center;
    color: #212121;
  }

  .subtitle {
    display: block;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    color: #212121;
  }
`;

const Form = styled.form`
  box-sizing: border-box;
  width: 100%;
  padding: 0 22px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Credits = styled.div`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 600;
  line-height: 18.2px;
  color: #212121;

  a {
    text-decoration: underline;
    color: #ff8800;
  }
`;

const LoginPrompt = styled.div`
  margin-top: 32px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: center;

  a {
    text-decoration: underline;
    font-weight: 700;
    color: #ce0868;
  }
`;
