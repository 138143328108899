import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../redux";
import { useCurrentUser } from "./useCurrentUser";
import { VideoModel } from "../../video/video.types";
import { likeContent } from "../endpoints/likeContent";
import { unlikeContent } from "../endpoints/unlikeContent";
import { preflightUser } from "../utils/preflightUser";
import { setUser } from "../../../redux/user/user.reducer";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";
import { iosGenerateHaptic, iosPlaySound } from "../../../tools/ios";
import { HapticEffect, SoundEffect } from "../../../interfaces";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { gaEventVideoLike } from "../../../tools/analytics/videoAnalytics";

export function useLikeVideo(video: VideoModel | null, analyticsListName: AnalyticsItemList) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentUser } = useCurrentUser();
  const [isReady, setReady] = useState(false);
  const isLikedStore = currentUser?.likedContent?.some((content: { _id: string }) => {
    return content._id === video?._id;
  });
  const [isLikedOptimistic, setLikedOptimistic] = useState(isLikedStore);
  const [isFetching, setFetching] = useState(false);

  // Setup hook and state once video is loaded
  useEffect(() => {
    if (video) {
      setLikedOptimistic(isLikedStore);
      setReady(true);
    }
  }, [video]);

  useEffect(() => {
    if (isReady && !isFetching) {
      if (isLikedOptimistic !== isLikedStore) {
        updateStatus();
      }
    }
  }, [isLikedOptimistic, isLikedStore]);

  async function toggleLike() {
    try {
      if (!video) throw new Error("Invalid video.");

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.likeContent"),
      });
      if (isRedirected) return;

      setLikedOptimistic(!isLikedOptimistic);
      iosGenerateHaptic(HapticEffect.SUCCESS);
      displayToast(t(!isLikedOptimistic
        ? "you have liked a video"
        : "you have unliked a video"
      ), "success");

      if (!isLikedOptimistic) {
        iosPlaySound(SoundEffect.LIKE);
        gaEventVideoLike({
          video,
          listName: analyticsListName,
        });
      }
    } catch (error) {
      console.error("Couldn't like/unlike video.", error);
      displayToast(t("error:default"));
    }
  }

  async function updateStatus() {
    try {
      if (!video) throw new Error("Invalid video.");

      setFetching(true);
      const [{ likedContent }] = isLikedOptimistic
        ? await likeContent(video?._id)
        : await unlikeContent(video?._id);

      setFetching(false);
      dispatch(setUser({ ...currentUser, likedContent }));
    } catch (error) {
      console.error("Couldn't update likes.", error);
    }
  }

  return {
    isLiked: isLikedOptimistic,
    toggleLike,
  };
}
