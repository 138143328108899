import { useHistory } from "react-router-dom";
import { ArticleModel } from "../../article/article.types";
import { useCurrentUser } from "./useCurrentUser";
import { shareArticle } from "../endpoints/shareArticle";
import { preflightUser } from "../utils/preflightUser";
import { refreshCachedArticle } from "../../article/utils/refreshCachedArticle";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { SupportedLanguagesEnum } from "../../../interfaces";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";
import { isAndroid } from "react-device-detect";
import { androidShare } from "../../../tools/android";
import i18n from "../../../config/i18n";
import { gaEventArticleShare } from "../../../tools/analytics/articleAnalytics";

export function useShareArticle(article: ArticleModel | null, analyticsListName: AnalyticsItemList) {
  const history = useHistory();
  const { currentUser } = useCurrentUser();

  return async function () {
    try {
      if (!article) throw new Error("Invalid article.");

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.shareArticle"),
      });
      if (isRedirected) return;

      const url = `
        ${window.location.origin}/post/${article.slug}?shared=${currentUser?.uid ?? null}
      `;

      // TODO: localize
      const text = i18n.resolvedLanguage === SupportedLanguagesEnum.FR
        ? `Je pense que cette publication scientifique pourrait t'intéresser: ${article.title}.`
        : `I think you might be interested in this scientific publication: ${article.title}.\n`;

      if (isAndroid) {
        return androidShare(url, text);
      }

      if (!navigator.share) {
        displayToast(t("toast.fail.browser"));
        return;
      }

      await navigator.share({
        title: document.title,
        text,
        url,
      });

      await shareArticle(article.slug);
      refreshCachedArticle(article.slug);

      gaEventArticleShare({
        article,
        listName: analyticsListName,
      });

      displayToast(t("you have shared an article"), "success");
    } catch (error) {
      console.error("Couldn't share article.", error);
      displayToast(t("error:default"));
    }
  };
}