import { ReactChildren, ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import GestureDetector from "../../GestureDetector";

// NOTE: This component is experimental.
// It will replace the unmaintained package we currently use.

const TRANSITION_TIME_OPEN = 0.5;
const TRANSITION_TIME_CLOSE = 0.2;

export default function BottomPanel({
  children,
  isOpen,
  onClose,
}: {
  children: ReactNode | ReactChildren;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [isVisible, setVisible] = useState(false);
  // NOTE: This state setter is used to update the component when ref changes.
  const [, setPanelOffsetY] = useState(0);
  const panelRef = useRef<HTMLDivElement>(null);
  const panelHeight = panelRef.current?.offsetHeight ?? 0;
  // NOTE: Using ref for more fluid animations.
  const panelOffsetYRef = useRef<number>(0);
  const panelOpenRatio = isOpen ? 1 - panelOffsetYRef.current / panelHeight : 0;

  useEffect(() => {
    if (panelRef.current?.offsetHeight) {
      setTimeout(() => {
        setVisible(true);
      }, 100);
    } else {
      setVisible(false);
    }
  }, [panelRef.current?.offsetHeight]);

  function handleDrag(_: unknown, y: number) {
    if (isOpen) {
      if (y > 0) {
        setPanelOffsetY(y);
        panelOffsetYRef.current = y;
      } else if (y === 0) {
        if (panelOffsetYRef.current < panelHeight * 0.5) {
          setPanelOffsetY(0);
          panelOffsetYRef.current = 0;
        } else {
          onClose();
          // Reset drag offset once invisible
          setTimeout(() => {
            setPanelOffsetY(0);
            panelOffsetYRef.current = 0;
          }, 500);
        }
      }
    }
  }

  return (
    <>
      <GestureDetector
        onDrag={handleDrag}
      >
        <Overlay
          className={isOpen ? "open" : ""}
          onClick={() => {
            onClose();
          }}
        />
        <PanelContainer
          ref={panelRef}
          className={isVisible ? isOpen ? "open" : "" : "hidden"}
          computedHeight={panelHeight}
          offsetY={panelOffsetYRef.current}
          openRatio={panelOpenRatio}
          transitionTimeOpen={TRANSITION_TIME_OPEN}
          transitionTimeClose={TRANSITION_TIME_CLOSE}
        >
          <div className="children-wrapper">
            {children}
          </div>
        </PanelContainer>
      </GestureDetector>
    </>
  );
}

const Overlay = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 100vh;
  left: 0;
  z-index: 100;
  width: 100%;
  min-height: 100dvh;
  background-color: #0009;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, top 0.0s 0.5s;

  &.open {
    top: 0;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
`;

const PanelContainer = styled.div<{
  computedHeight: number;
  offsetY: number;
  openRatio: number;
  transitionTimeOpen: number;
  transitionTimeClose: number;
}>`
  box-sizing: border-box;
  position: fixed;
  bottom: -${({ computedHeight }) => computedHeight}px;
  left: 0;
  z-index: 100;
  width: 100%;
  padding-top: 20px;
  padding-bottom: env(safe-area-inset-bottom);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: white;
  transition: bottom 0.3s ;

  &.hidden {
    bottom: -1000vh !important;
  }

  &.open {
    bottom: ${({ offsetY }) => -offsetY}px;
    transition: bottom 0.5s ;
  }

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 50%;
    width: 36px;
    height: 3px;
    border-radius: 2px;
    background-color: #0002;
    transform: translateX(-50%);
  }

  /* &::after {
    content: "";
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 30000;
    width: 100%;
    height: 96px;
    background-image: linear-gradient(transparent, #fff);
    opacity: ${({ openRatio }) => openRatio};
  } */
`;
