import React, { Component } from "react";
import styled from "styled-components";
import { Drawer } from "@mui/material";
import FiltersPage from "./FiltersPage";
import { iosDismissKeyboard } from "../../tools/ios";
import { androidDismissKeyboard } from "../../tools/android";
import { gaEventSetFilter } from "../../tools/analytics/filterAnalytics";

class FiltersDrawer extends Component {
  inputDrawerRef = React.createRef();

  handleDismissKeyboard = () => {
    this.inputDrawerRef.current.blur();
    iosDismissKeyboard();
    androidDismissKeyboard();
  };

  handleSelectFilters = ({ medical_specialties, tags }) => {
    if (typeof medical_specialties === "string") {
      medical_specialties = [tags?.[0]?.parent];
    }

    gaEventSetFilter({
      specialties: medical_specialties,
      tags,
    });
  };

  render() {
    return (
      <StyledDrawer
        anchor='left'
        open={this.props.showDrawer}
        disableScrollLock={false}
        onClose={this.props.onClose}
        onScroll={this.handleDismissKeyboard.bind(this)}
      >
        <div style={{ width: window.innerWidth }}>
          <FiltersPage
            onSubmit={(filters) => {
              this.props.handleFilters(filters);
              this.handleSelectFilters(filters);
            }}
            inputDrawerRef={this.inputDrawerRef}
            {...this.props}
          />
        </div>
      </StyledDrawer>
    );
  }
}

export default FiltersDrawer;

const StyledDrawer = styled(Drawer)`
  width: 100%;
  box-sizing: border-box;

  .MuiDrawer-paper {
    box-sizing: border-box;
    overflow: scroll;
  }
`;