export enum AnalyticsItemVariant {
  ARTICLE = "article",
  PLAYLIST = "playlist",
  PODCASTS = "podcasts",
  // PROFILE = "profile",
  JOURNALS = "journals",
  NECTAR = "nectar",
  ORGANISATION = "organisation",
  VIDEO_STORY = "video_story",
  VIDEO_ARTICLE = "video_article",
}

export enum AnalyticsItemCategory {
  STANDARD = "standard",
  SPONSORED = "sponsored",
}

export enum AnalyticsItemList {
  HOME = "home",
  FORYOU = "foryou",
  SEARCH = "search",
  // FILTERS = "filters",
  DISCOVERY_MAIN_CAROUSEL = "discovery_main_carousel",
  DISCOVERY_SECOND_CAROUSEL = "discovery_second_carousel",
  DISCOVERY_VIDEOS = "discovery_videos",
  // DISCOVERY_PLAYLIST = "discovery_playlist",
  ARTICLE_PAGE = "article_page",
  JOURNAL_PAGE = "journal_page",
  PLAYLIST_PAGE = "playlist_page",
  NECTAR_PAGE = "nectar_page",
  ORGANISATION_PAGE = "organisation_page",
  STORY_LIST_PAGE = "story_list_page",
  VIDEO_ARTICLE_PAGE = "videos_article_page",
  AUDIO_ROOM_LIST = "audio_room_list",
  AUDIO_ROOM_RESUME_PODCAST = "audio_room_resume_podcast",
}

export const AUDIO_PERCENTAGES = [10, 25, 50, 75, 80, 100];
