import { useEffect } from "react";
import { useAppSelector } from "../../redux";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { useSearchPlaylists } from "../../domains/search/hooks/useSearchPlaylists";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventViewItemListPlaylist } from "../../tools/analytics/playlistAnalytics";
import Loader from "../Loader";
import FadeIn from "react-fade-in/lib/FadeIn";
import InfiniteScroll from "react-infinite-scroll-component";
import PlaylistCard from "../playlists/PlaylistCard";
import { NoResultText, SearchResultsListWrapper } from "./layout";
import { t } from "i18next";

// TODO: Move?
function prioritizePlaylists(list: PlaylistModel[]) {
	const prioritizedList = [...list];
	prioritizedList.sort((a, b) => {
		if (a.sponsored && !b.sponsored) {
			return -1;
		} else if (!a.sponsored && b.sponsored) {
			return 1;
		} else {
			return 0;
		}
	});
	return prioritizedList;
}

export default function SearchResultsPlaylists() {
	const filtersApplied = useAppSelector((state) => state.search.filtersApplied);
	const searchValueParam = new URLSearchParams(location.search).get("value") ?? "";
	const searcher = useSearchPlaylists(searchValueParam);

	useEffect(() => {
		for (const list of [searcher.fallback, searcher.results]) {
			if (list.length) {
				gaEventViewItemListPlaylist({
					playlists: list,
					listName: AnalyticsItemList.SEARCH,
				});
			}
		}
	}, [searcher.fallback, searcher.results]);

	if (!searchValueParam && !filtersApplied) {
		if (searcher.isLoadingFallback) {
			return <Loader loaderOnly />;
		}

		return (
			<FadeIn key="fallback">
				<SearchResultsListWrapper>
					{searcher.fallback.map((playlist: PlaylistModel) => {
						return (
							<PlaylistCard
								key={playlist._id}
								playlist={playlist}
							/>
						);
					})}
				</SearchResultsListWrapper>
			</FadeIn>
		);
	}

	if (searcher.isLoadingResults) {
		return <Loader loaderOnly />;
	}

	if (!searcher.results.length) {
		return <NoResultText>{t("common:noSearchResults")}</NoResultText >;
	}

	return (
		// NOTE: specific key to force fade when switching results
		<FadeIn key="results">
			<InfiniteScroll
				dataLength={searcher.results.length}
				next={() => searcher.searchMore()}
				hasMore={searcher.results.length < searcher.total}
				pullDownToRefresh={false}
				scrollableTarget="content-ref"
				loader={null}
			>
				<SearchResultsListWrapper>
					{prioritizePlaylists(searcher.results).map((playlist: PlaylistModel) => {
						return (
							<PlaylistCard
								key={playlist._id}
								playlist={playlist}
							/>
						);
					})}
					{searcher.isLoadingResults && <Loader loaderOnly />}
				</SearchResultsListWrapper>
			</InfiniteScroll>
		</FadeIn>
	);
}
