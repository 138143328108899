import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { API_URL } from "../config";
import { getFirebaseToken } from "../services/firebase";
import { store } from ".";

// TODO : use process.env.VITEST to check if 
// the baseQuery should be set to a mock

export const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  prepareHeaders: async (headers) => {
    headers.set("Cache-Control", "no-cache");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("ngrok-skip-browser-warning", "any");

    const accessToken = await getFirebaseToken();
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    const currentRoomId = localStorage.getItem("currentRoomId");
    if (currentRoomId) headers.set("x-juisci-room", currentRoomId);

    return headers;
  },
});
