import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SupportedLanguagesEnum } from "../../interfaces";
import {
  iosRequestReview,
  iosSetBackgroundColor,
  isNativeIOS,
} from "../../tools/ios";
import { androidRequestReview, isNativeAndroid } from "../../tools/android";
import { getStoredAppLang } from "../../domains/app/utils/langUtils";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { getUserStartingPage } from "../../domains/user/utils/getUserStartingPage";
import { memorizeArticlesList, memorizeMainSpecialtyArticles } from "../../domains/article/article.reducer";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";
import { useLazyGetArticlesListQuery } from "../../domains/article/endpoints/getArticlesList";
import { useLazyGetVideoStoriesListQuery } from "../../domains/video/endpoints/getVideoStoriesList";
import { gaEventPageView } from "../../tools/analytics/analyticsEvents";
import FadeIn from "react-fade-in/lib/FadeIn";
import Lottie from "lottie-react";
import IntroEN from "../../assets/animations/intro-en.json";
import IntroFR from "../../assets/animations/intro-fr.json";
import disableScroll from "disable-scroll";
import styled from "styled-components";
import i18n, { DEFAULT_LANGUAGE } from "../../config/i18n";

export const JUISCI_VERSION = 4.0;
// NOTE: Translation files take time to load.
// These texts are hardcoded to be displayed instantly.
const SUBLINES = {
  [SupportedLanguagesEnum.EN]: "Juisci puts science in motion",
  [SupportedLanguagesEnum.FR]: "Juisci fait bouger la science",
  [SupportedLanguagesEnum.IT]: "Juisci puts science in motion",
  [SupportedLanguagesEnum.ES]: "Juisci puts science in motion",
}

export default function SplashPage({ onComplete }: {
  onComplete: Function;
}) {
  const history = useHistory();
  // NOTE: useAppDispatch doesn't work here.
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const appLang = getStoredAppLang() ?? DEFAULT_LANGUAGE;
  const [isPrefetchDone, setPrefetchDone] = useState(false);
  const [isAuthentified, setAuthentified] = useState(false);
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const [getArticlesList] = useLazyGetArticlesListQuery();
  const [getVideoStoriesList] = useLazyGetVideoStoriesListQuery();

  useEffect(() => {
    (async function () {
      disableScroll.on();
      if (isNativeIOS) iosSetBackgroundColor("#FFFFFF");

      if (appLang) {
        clearStorage();
        requestReview();
        await preFetchContent();
      }
    })();
  }, [appLang]);

  useEffect(() => {
    if (isPrefetchDone && !!currentUser) {
      // NOTE: Skip splash screen on debug.
      // NOTE: This has to be here because of some weird behavior on home carousels.
      const { NODE_ENV, REACT_APP_SKIP_SPLASH_SCREEN } = process.env;
      if (NODE_ENV === "development" && !!REACT_APP_SKIP_SPLASH_SCREEN) {
        handlePageLoaded();
      }
    }
  }, [isPrefetchDone, currentUser]);

  function requestReview() {
    const countReqReview = parseInt(localStorage.getItem("countReqReview") ?? "0");

    if (isNativeIOS || isNativeAndroid && countReqReview >= 0) {
      if ((countReqReview && !(countReqReview % 7)) || countReqReview === 1) {
        if (isNativeIOS) iosRequestReview();
        if (isNativeAndroid) androidRequestReview();
      }

      localStorage.setItem("countReqReview", JSON.stringify(countReqReview + 1));
    } else {
      localStorage.setItem("countReqReview", "0");
    }
  };

  function clearStorage() {
    localStorage.setItem("msgTabIndex", "0");
    localStorage.removeItem("foryou_carousel_index");
  };

  async function preFetchContent() {
    try {
      const isAuthentified = await isUserAuthentified();
      setAuthentified(isAuthentified);
      if (isAuthentified) {
        await getCurrentUser();
        prefetchVideos();
      }
      await prefetchArticles(isAuthentified);
      setPrefetchDone(true);
    } catch (error) {
      console.error("Couldn't prefetch content on splash page.", error);
    }
  };

  async function prefetchArticles(isAuthentified: boolean) {
    try {
      const params: any = {
        limit: 5,
        offset: 0,
        language: appLang,
      };
      if (isAuthentified) {
        params.medicalSpecialties = currentUser?.main_specialty?.uid;
      }

      const { docs } = await getArticlesList(params).unwrap();
      dispatch(isAuthentified
        ? memorizeMainSpecialtyArticles(docs)
        : memorizeArticlesList(docs)
      );
    } catch (error) {
      console.error("Couldn't prefetch articles.", error);
    }
  }

  async function prefetchVideos() {
    try {
      await getVideoStoriesList({
        limit: 10,
        offset: 0,
        language: appLang,
      })
        .unwrap()
        .catch((error) => {
          throw error;
        });

    } catch (error) {
      console.error("Couldn't prefetch videos.", error);
    }
  }

  async function handlePageLoaded() {
    onComplete && onComplete();

    gaEventPageView();

    // If router is active and on root path, handle redirections
    if (history && location.pathname === "/") {
      const welcomeDone = parseInt(localStorage.getItem("welcomeDone") ?? "0");
      if (welcomeDone < JUISCI_VERSION) return history.push("/welcome");

      const path = currentUser ? await getUserStartingPage(currentUser) : null;
      if (path) {
        history.push(path);
      } else {
        history.push(isAuthentified ? "/foryou" : "/discovery");
      }
    }
  }

  return (
    <SplashContainer>
      <div className="animation-container">
        <Lottie
          className="animation-lottie"
          animationData={
            {
              [SupportedLanguagesEnum.EN]: IntroEN,
              [SupportedLanguagesEnum.FR]: IntroFR,
            }[i18n?.resolvedLanguage] ?? IntroEN
          }
          loop={false}
          autoplay={true}
          onComplete={handlePageLoaded}
        />
        <div className="subline">
          <FadeIn delay={1000} transitionDuration={1000} >
            {SUBLINES[appLang as SupportedLanguagesEnum]}
          </FadeIn>
        </div>
      </div>
    </SplashContainer>
  );
}

export const SplashContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  .animation-container {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);
    z-index: 1;
    width: 100%;
  }

  .animation-lottie {
    width: 300px;
    margin: auto;
  }

  .subline {
    font-family: "Inter";
    font-weight: 400;
    font-size: 21px;
    position: relative;
    text-align: center;
    z-index: 2;
    margin-top: -36px;
    padding: 0 24px;
    white-space: pre-wrap;
  }
`;
