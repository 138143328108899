import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import disableScroll from "disable-scroll";
import { BottomSheet } from "react-spring-bottom-sheet";
import CustomIcon from "../CustomIcon";
import { CustomButton } from "../global";
import styled from "styled-components";
import { t } from "i18next";
import { ReactComponent as JuisciLogo } from "../../assets/core/juisci-logo.svg";
import JuisciPattern from "../../assets/images/mobile-pattern.svg";

export default function LoginSheet({ isOpen, onClose }: {
  isOpen: boolean;
  onClose: Function;
}) {
  const history = useHistory();

  useEffect(() => {
    if (isOpen) disableScroll.on();
    else disableScroll.off();
  }, [isOpen])

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={() => setTimeout(() => onClose(), 100)}
      expandOnContentDrag
    >
      <Wrapper>
        <Container>
          <CloseButtonWrapper>
            <CustomIcon iconName="close_alt" onClick={onClose} />
          </CloseButtonWrapper>

          <JuisciLogo />

          <Title>{t("login:sheet.title")}</Title>

          <BottomContainer>
            <Text>{t("login:sheet.description")}</Text>

            <ActionContainer>
              <CustomButton
                className="white"
                onClick={() => {
                  onClose();
                  history.push("/signup/pre");
                }}
              >
                {t("login:sheet.action.register")}
              </CustomButton>

              <CustomButton
                onClick={() => {
                  onClose();
                  localStorage.setItem("authRedirectPath", history.location.pathname);
                  history.push("/login");
                }}
              >
                {t("login:sheet.action.login")}
              </CustomButton>
            </ActionContainer>
          </BottomContainer>

        </Container>
        <ImagePattern src={JuisciPattern} alt='' />
      </Wrapper>
    </BottomSheet>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  min-height: 70dvh;
  z-index: 97 !important;
`;

const Container = styled.div`
  padding: 0 23px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px;
`;

const CloseButtonWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
`;

const Title = styled.div`
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: center;
  color: #212121;
`;

const BottomContainer = styled.div`
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const ActionContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const Text = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  color: #212121;
`;

const ImagePattern = styled.img`
  object-fit: cover;
  width: 100%;
  margin-bottom: -5px;
`;
