import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux";
import { UserConnectionRequestModel, UserModel } from "../user.types";
import { useLazyGetAcceptedConnectionRequestsQuery } from "../endpoints/getAcceptedConnectionRequests";
import { useLazyGetPendingConnectionRequestsQuery } from "../endpoints/getPendingConnectionRequests";
import { useCurrentUser } from "./useCurrentUser";
import { preflightUser } from "../utils/preflightUser";
import { sendConnectionRequest } from "../endpoints/sendConnectionRequest";
import { cancelConnectionRequest } from "../endpoints/cancelConnectionRequest";
import { acceptConnectionRequest } from "../endpoints/acceptConnectionRequest";
import { rejectConnectionRequest } from "../endpoints/rejectConnectionRequest";
import { unfollowUser } from "../endpoints/unfollowUser";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";
import { gaEventUserFollowProfile } from "../../../tools/analytics/userAnalytics";

export function useUserConnection(otherUser: UserModel | null) {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const acceptedConnections = useAppSelector((state) => state.user.connections.accepted);
  const pendingConnections = useAppSelector((state) => state.user.connections.pending);
  const [getAcceptedConnectionRequests] = useLazyGetAcceptedConnectionRequestsQuery();
  const [getPendingConnectionRequests] = useLazyGetPendingConnectionRequestsQuery();

  let isConnected, isPendingSent, isPendingReceived = false;

  if (otherUser) {
    isConnected = acceptedConnections.some((request: UserConnectionRequestModel) => {
      return request.toUserId === otherUser._id || request.fromUserId === otherUser._id;
    });
    isPendingSent = pendingConnections.some((request: UserConnectionRequestModel) => {
      // NOTE: Double check here because the backend sends all pending requests between all users.
      return request.fromUserId === currentUser._id && request.toUserId === otherUser._id;
    });
    isPendingReceived = pendingConnections.some((request: UserConnectionRequestModel) => {
      // NOTE: Double check here because the backend sends all pending requests between all users.
      return request.toUserId === currentUser._id && request.fromUserId === otherUser._id;
    });
  }

  useEffect(() => {
    refreshConnections();
  }, []);

  async function refreshConnections() {
    if (!currentUser) return;

    await getAcceptedConnectionRequests()
      .unwrap()
      .catch((error: any) => {
        console.error("Couldn't get accepted connection requests.", error);
      });
    await getPendingConnectionRequests()
      .unwrap()
      .catch((error) => {
        console.error("Couldn't get pending connection requests.", error);
      });
  }

  async function sendRequest() {
    try {
      if (!otherUser) return;
      const { isRedirected } = await preflightUser({
        history,
        authOnly: true,
        memorizePath: true,
      });
      if (isRedirected) return;

      await sendConnectionRequest(otherUser._id);
      refreshConnections();
      displayToast(t("toast.success.req_sent"), "success");
      gaEventUserFollowProfile();
    } catch (error) {
      console.error("Couldn't send connection request.", error);
    }
  }

  async function cancelRequest() {
    try {
      if (!otherUser) return;
      const requestId = pendingConnections.find((request: UserConnectionRequestModel) => {
        return [request.fromUserId, request.toUserId].includes(otherUser._id);
      }).id;
      await cancelConnectionRequest(requestId);
      refreshConnections();
      displayToast(t("toast.success.req_cancelled"), "success");
    } catch (error) {
      console.error("Couldn't cancel connection request.", error);
    }
  }

  async function unfollow() {
    try {
      if (!otherUser) return;
      const requestId = acceptedConnections.find((request: UserConnectionRequestModel) => {
        return [request.fromUserId, request.toUserId].includes(otherUser._id);
      }).id;
      await unfollowUser(requestId);
      refreshConnections();
      displayToast(t("toast.success.user_unfollowed"), "success");
    } catch (error) {
      console.error("Couldn't unfollow user.", error);
    }
  }

  function getRequestIdToAcceptOrReject() {
    const connection = pendingConnections.find((request: UserConnectionRequestModel) => {
      return request.fromUserId === otherUser?._id;
    });
    return connection?.id ?? null;
  }

  async function acceptRequest() {
    try {
      const requestId = getRequestIdToAcceptOrReject();
      if (!requestId) throw new Error("Couldn't find request to accept.");
      await acceptConnectionRequest(requestId);
      refreshConnections();
    } catch (error) {
      console.error("Couldn't accept connection request.", error);
    }
  }

  async function rejectRequest() {
    try {
      const requestId = getRequestIdToAcceptOrReject();
      if (!requestId) throw new Error("Couldn't find request to reject.");
      await rejectConnectionRequest(requestId);
      refreshConnections();
    } catch (error) {
      console.error("Couldn't reject connection request.", error);
    }
  }

  return {
    isConnected,
    isPendingSent,
    isPendingReceived,
    sendRequest,
    cancelRequest,
    acceptRequest,
    rejectRequest,
    unfollow,
  };
}
