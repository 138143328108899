import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../redux";
import { useCurrentUser } from "../../user/hooks/useCurrentUser";
import { preflightUser } from "../../user/utils/preflightUser";
import { followJournal } from "../endpoints/followJournal";
import { unfollowJournal } from "../endpoints/unfollowJournal";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";
import { gaEventJournalFollow } from "../../../tools/analytics/journalAnalytics";
import { iosGenerateHaptic } from "../../../tools/ios";
import { HapticEffect } from "../../../interfaces";
import { JournalModel } from "../journal.types";
import { setUser } from "../../../redux/user/user.reducer";

export function useJournalFollow(journal: JournalModel | null) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentUser } = useCurrentUser();
  const [isReady, setReady] = useState(false);
  const isFollowedStore = currentUser.journals
    ?.some((el: JournalModel) => el?._id === journal?._id);
  const [isFollowedOptimistic, setFollowedOptimistic] = useState(isFollowedStore);
  const [isFetching, setFetching] = useState(false);

  // Setup hook and state once journal is loaded
  useEffect(() => {
    if (journal) {
      setFollowedOptimistic(isFollowedStore);
      setReady(true);
    }
  }, [journal]);

  useEffect(() => {
    if (isReady && !isFetching) {
      if (isFollowedOptimistic !== isFollowedStore) {
        updateStatus();
      }
    }
  }, [isFollowedStore, isFollowedOptimistic]);

  async function toggleFollow() {
    try {
      if (!journal) throw new Error("Invalid journal.");

      const { isRedirected } = await preflightUser({ history, authOnly: true });
      if (isRedirected) return;

      setFollowedOptimistic(!isFollowedOptimistic);
      iosGenerateHaptic(HapticEffect.SELECTION);
      displayToast(t(!isFollowedOptimistic
        ? "You are now following"
        : "You are no longer following"
      ) + " " + journal?.name + " 🗞");

      if (!isFollowedOptimistic) {
        gaEventJournalFollow();
      }
    } catch (error) {
      displayToast(t("error:default"));
      console.error("Couldn't follow/unfollow journal.", error);
    }
  }

  async function updateStatus() {
    try {
      if (!journal) throw new Error("Invalid journal.");

      setFetching(true);
      const { journals } = isFollowedOptimistic
        ? await followJournal(journal?._id)
        : await unfollowJournal(journal?._id);

      setFetching(false);
      dispatch(setUser({ ...currentUser, journals }));
    } catch (error) {
      console.error("Couldn't update follow status.", error);
    }
  }

  return {
    isFollowed: isFollowedOptimistic,
    toggleFollow,
  };
}