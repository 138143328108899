import { Component } from "react";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavHome } from "../../components/app/headers/layout";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import VideoCard from "../../components/VideoCard";
import VideoCard2 from "../../components/cards/VideoCard";
import Loader from "../../components/Loader";
import { t } from "i18next";
import { userApi } from "../../redux/user/user.service";
import { connect } from "react-redux";
import FiltersDrawer from "../../components/filters/FiltersDrawer";
import LoginSheet from "../../components/signup/LoginSheet";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventViewItemListVideo } from "../../tools/analytics/videoAnalytics";
import { getVideoArticlesList } from "../../domains/video/endpoints/getVideoArticlesList";
import { getVideoStoriesList } from "../../domains/video/endpoints/getVideoStoriesList";
import i18n from "../../config/i18n";
import HomeContentFilters from "../../components/home/layout/HomeContentFilters";
import ItemList from "../../components/app/ItemList";

class HomeVideos extends Component {
  state = {
    isLoading: (!this.props.videos.cache.videoArticles.length && !this.props.videos.cache.videoStories.length),
    isAuthentified: false,
    medical_specialties: [],
    showDrawer: false,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);

    const isAuthentified = await isUserAuthentified();
    this.setState({ isAuthentified });

    if (localStorage.getItem("homeVideosFilters")) {
      const { medical_specialties, tags } = JSON.parse(
        localStorage.getItem("homeVideosFilters")
      );
      this.setState({ medical_specialties, tags }, async () => {
        await this.handleFetchVideos();
      });
    } else {
      this.handleFetchVideos();
    }

    if (isAuthentified) {
      this.props.getUser();
      this.props.getPlaylists();
    }
    this.triggerLoginSheet();
  }

  handleFetchVideos = async (videoFormat = undefined) => {
    const medical_specialties =
      !!this.state.medical_specialties && this.state.medical_specialties.length
        ? [...this.state.medical_specialties.map((el) => el.uid)].toString()
        : undefined;

    const tags =
      !!this.state.tags && this.state.tags.length
        ? [...this.state.tags.map((el) => el.uid)].toString()
        : undefined;

    const reqParams = {
      offset: 0,
      language: i18n.resolvedLanguage,
    };

    if (videoFormat === undefined) {
      await this.props.getVideoArticlesList({
        ...reqParams,
        limit: 50,
        medicalSpecialties: medical_specialties,
        tags,
      })
        .unwrap()
        .catch((error) => {
          console.error("Couldn't load video articles.", error);
        });

      await this.props.getVideoStoriesList({
        ...reqParams,
        limit: 100,
      });
    } else if (videoFormat === "story") {
      delete reqParams.tags;
      delete reqParams.medical_specialties;

      await this.props.getVideoStoriesList({
        ...reqParams,
        limit: 100,
      });
    } else if (videoFormat === "article")
      await this.props.getVideoArticlesList({
        ...reqParams,
        limit: 50,
        medicalSpecialties: medical_specialties,
        tags,
      })
        .unwrap()
        .catch((error) => {
          console.error("Couldn't load video articles.", error);
        });

    this.setState({
      videos: { ...this.props.videos },
      isLoading: false,
    });

    // NOTE: Should be set with a useEffect or something, not here like this.
    gaEventViewItemListVideo({
      videos: this.props.videos.cache.videoArticles,
      listName: AnalyticsItemList.DISCOVERY_VIDEOS,
    });
    gaEventViewItemListVideo({
      videos: this.props.videos.cache.videoStories.slice(0, 10),
      listName: AnalyticsItemList.DISCOVERY_VIDEOS,
    });
  };

  handleFilters = ({
    tags = undefined,
    medical_specialties,
    fetchedTags = undefined,
  }) => {
    const filters = {
      tags,
      medical_specialties,
    };
    localStorage.setItem("homeVideosFilters", JSON.stringify(filters));
    this.setState(
      { showDrawer: false, medical_specialties, tags, fetchedTags },
      () => this.handleFetchVideos("article")
    );
  };

  triggerLoginSheet = () => {
    const query = new URLSearchParams(this.props.location.search);
    const wasShared = JSON.parse(query.get("wasShared"));

    if (wasShared && !this.state.isAuthentified)
      this.setState({ showLoginSheet: true });
  };

  render() {
    const stories = this.props.videos.cache.videoStories.slice(0, 10);

    if (this.state.isLoading) return <Loader />;

    return (
      <PageContainer>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavHome /></HeaderLinks>
          <HeaderTitle>{t("Videos")}</HeaderTitle>
          <HeaderLinks />
        </HeaderBoxAutoSafe>

        <LoginSheet
          {...this.props}
          isOpen={this.state.showLoginSheet}
          onClose={() => this.setState({ showLoginSheet: false })}
        />

        <FiltersDrawer
          showDrawer={this.state.showDrawer}
          onClose={() => this.setState({ showDrawer: false })}
          medical_specialties={this.state.medical_specialties}
          handleFilters={this.handleFilters}
          hideTags
        />

        <div className='heading'>
          <h5>{t("Whats up doc")}</h5>
          <Link to='/videos/stories'>{t("common:action.seeAll")}</Link>
        </div>
        <HScrollContainer className="scrollbar-hidden">
          {stories?.map((story) => {
            return (
              <VideoCard
                variant='story'
                key={story._id + "--story-homevideocard"}
                video={story}
                onClick={() =>
                  this.props.history.push({
                    pathname: "/video/story/" + story.slug,
                    state: {
                      story,
                      stories,
                    },
                  })
                }
              />
            );
          })}
        </HScrollContainer>

        <hr style={{ margin: "30px 0" }} />

        <div className='heading'>
          <h5>{t("KOL-laborative Care")}</h5>
          <HomeContentFilters
            active={
              this.state.tags?.length > 0 ||
              this.state.medical_specialties?.length > 0
            }
            onClick={() => this.setState({ showDrawer: true })}
          />
        </div>
        <ItemList>
          {this.props.videos.cache.videoArticles?.map((video) => (
            <VideoCard2
              key={video._id + "--article-homevideocard"}
              video={video}
              {...this.props}
            />
          ))}
        </ItemList>
      </PageContainer>
    );
  }
}

function mapState(state) {
  const user = state.user;
  const videos = state.videos;

  return {
    user,
    videos,
  };
}
const mapDispatch = {
  getUser: userApi.endpoints.getUser.initiate,
  getPlaylists: userApi.endpoints.getPlaylists.initiate,
  getVideoArticlesList: getVideoArticlesList.initiate,
  getVideoStoriesList: getVideoStoriesList.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(withRouter(HomeVideos));

const PageContainer = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: #f7f8fc;

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;

    span {
      color: #ce0868;
    }
  }

  a {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #ce0868;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 21px;
  }
`;

const HScrollContainer = styled.div`
  overflow-x: auto;
  padding: 0 24px 24px;
  display: flex;
  align-items: stretch;
  gap: 8px;
`;
