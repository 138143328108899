import { useEffect, useState } from "react";
import { defaultAvatarImages } from "../../domains/image/utils/pickUserImage";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import disableScroll from "disable-scroll";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderLogo, NavBack, NavLang } from "../../components/app/headers/layout";
import StepBars from "../../components/app/StepBars";
import { OnboardingBottomPanel, OnboardingHeading, OnboardingHero } from "../../components/onboarding/layout";
import CustomAvatarUploader from "../../components/profile/CustomAvatarUploader";
import DefaultAvatarSelector from "../../components/profile/DefaultAvatarSelector";
import { CustomButton } from "../../components/global";
import styled from "styled-components";

export default function OnboardingAvatar({ onCancel, onSubmit }: {
  onCancel: Function;
  onSubmit: Function;
}) {
  const { t } = useLangContext();
  const [customAvatarFile, setCustomAvatarFile] = useState("");
  const [selectedDefaultAvatar, setSelectedDefaultAvatar] = useState("");
  const canSubmit = !!customAvatarFile || !!selectedDefaultAvatar;

  useEffect(() => {
    disableScroll.off();
    window.scrollTo(0, 0);
  }, []);

  function handleChangeAvatarCustom(url: string) {
    setSelectedDefaultAvatar("");
    setCustomAvatarFile(url);
  }

  function handleChangeAvatarDefault(slug: string) {
    setSelectedDefaultAvatar(slug);
    setCustomAvatarFile("");
  }

  function handleSubmit() {
    onSubmit({
      customAvatar: customAvatarFile,
      defaultAvatar: selectedDefaultAvatar,
    });
  }

  return (
    <Wrapper>
      <HeaderBoxAutoSafe>
        <HeaderLinks><NavBack onClick={onCancel} /></HeaderLinks>
        <HeaderLogo />
        <HeaderLinks><NavLang /></HeaderLinks>
      </HeaderBoxAutoSafe>

      <OnboardingHero>
        <StepBars maxSteps={4} currentStep={4} style={{
          bar: { background: "#ECF0F5" },
        }} />
        <OnboardingHeading>
          <h1>{t("signup:onboarding.avatar.title")}</h1>
          <span className="subtitle">{t("signup:onboarding.avatar.subtitle")}</span>
        </OnboardingHeading>
      </OnboardingHero>

      <div style={{ padding: "0 30px" }}>
        <CustomAvatarUploader
          avatarUrl={
            customAvatarFile
              ? customAvatarFile
              : (defaultAvatarImages as { [key: string]: any })[selectedDefaultAvatar]?.default
          }
          buttonText={t("signup:onboarding.avatar.action.uploadYourPicture")}
          onChange={handleChangeAvatarCustom}
        />
      </div>

      <div style={{
        padding: `32px 22px ${!canSubmit ? 0 : 180}px`,
      }}>
        <h2>{t("signup:onboarding.avatar.pickDefaultAvatar")}</h2>
        <DefaultAvatarSelector
          selected={selectedDefaultAvatar}
          onChange={handleChangeAvatarDefault}
        />
      </div>

      <OnboardingBottomPanel className={!canSubmit ? "hide" : ""}>
        <CustomButton
          onClick={handleSubmit}
          className={!canSubmit ? "disabled" : "yellow"}
          disabled={!canSubmit}
        >
          {t("signup:onboarding.avatar.action.ready")}
        </CustomButton>
      </OnboardingBottomPanel>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  background: #FFFFFF;

  h2 {
    margin: 0 0 16px;
    font-family: Roboto;
    font-size: 21px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    color: #CE0868;
  }
`;