import { useEffect, useState } from "react";
import { SpecialtyModel } from "../../domains/app/app.types";
import { ProfessionModel } from "../../domains/profession/profession.types";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import { getProfessionByUid } from "../../domains/profession/endpoints/getProfessionByUid";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import Fuse from "fuse.js";
import Loader from "../../components/Loader";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderLogo, NavBack, NavLang } from "../../components/app/headers/layout";
import StepBars from "../../components/app/StepBars";
import {
  OnboardingHeading,
  OnboardingHero,
  OnboardingSelectionRow,
  OnboardingSelectionWrapper,
} from "../../components/onboarding/layout";
import CustomSearchInput from "../../components/CustomSearchInput";
import styled from "styled-components";
import i18n from "../../config/i18n";

export default function OnboardingSpecialty({ profession, onCancel, onSubmit }: {
  profession: ProfessionModel;
  onCancel: Function;
  onSubmit: Function;
}) {
  const { t } = useLangContext();
  const [isLoading, setLoading] = useState(true);
  const [specialtiesList, setSpecialtiesList] = useState<[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    (async function () {
      const { medical_specialties } = await getProfessionByUid(profession.uid)
      setSpecialtiesList(medical_specialties);
      setLoading(false);
    })();
  }, []);

  function handleSearch(value: string) {
    setSearchValue(value);

    if (value.length) {
      const options = {
        includeScore: true,
        threshold: 0.25,
        keys: [`translations.${i18n.resolvedLanguage}`],
      };
      const fuse = new Fuse(specialtiesList, options);
      const results = fuse.search(value).map((res) => res.item);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }

  function handleSelectSpecialty(specialty: SpecialtyModel) {
    onSubmit(specialty);
  }

  return (
    <Wrapper>
      <HeaderBoxAutoSafe>
        <HeaderLinks><NavBack onClick={onCancel} /></HeaderLinks>
        <HeaderLogo />
        <HeaderLinks><NavLang /></HeaderLinks>
      </HeaderBoxAutoSafe>

      {isLoading
        ? <Loader />
        : (
          <>
            <OnboardingHero>
              <StepBars maxSteps={4} currentStep={3} />
              <OnboardingHeading>
                <h1>
                  {t("signup:onboarding.specialty.title")}{" "}
                  {getItemTranslation(profession, i18n.resolvedLanguage)}
                </h1>
                <span className="subtitle">{t("signup:onboarding.specialty.subtitle")}</span>
              </OnboardingHeading>
            </OnboardingHero>

            <OnboardingSelectionWrapper style={{ flex: 1 }}>
              <span className="help">{t("signup:onboarding.specialty.prompt")}:</span>

              <div style={{ marginBottom: 32 }}>
                <CustomSearchInput
                  placeholder={t("common:action.search")}
                  onSearch={handleSearch}
                  onClear={() => {
                    setSearchValue("");
                    setSearchResults([]);
                  }}
                />
              </div>

              <h2>{t("signup:onboarding.specialty.specialties")}</h2>

              {(searchValue.length && !searchResults.length) ? (
                <span className="no-results">{t("common:noSearchResults")}</span>
              ) : (
                <>
                  {(searchResults.length ? searchResults : specialtiesList)
                    .sort((a: SpecialtyModel, b: SpecialtyModel) =>
                      getItemTranslation(a).localeCompare(getItemTranslation(b))
                    )
                    .map((specialty: SpecialtyModel) => {
                      return (
                        <OnboardingSelectionRow key={specialty.uid}>
                          <span>{getItemTranslation(specialty)}</span>
                          <button onClick={() => handleSelectSpecialty(specialty)}>
                            {t("common:action.select")}
                          </button>
                        </OnboardingSelectionRow>
                      );
                    })
                  }
                </>
              )}
            </OnboardingSelectionWrapper>
          </>
        )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
`;