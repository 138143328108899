import { Component } from "react";
import { SupportedLanguagesEnum } from "../../interfaces";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import { StyledOnboarding } from "../onboarding/StyledOnboarding";
import CustomSearchInput from "../CustomSearchInput";
import { t } from "i18next";
import styled from "styled-components";
import { CustomButton, Spacer } from "../global";
import { userApi } from "../../redux/user/user.service";
import { connect } from "react-redux";
import Loader from "../Loader";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack } from "../app/headers/layout";
import Fuse from "fuse.js";
import { getSpecialtyTags } from "../../domains/app/endpoints/getSpecialtyTags";
import CustomIcon from "../CustomIcon";
import { isNativeIOS } from "../../tools/ios";
import FilterSelectButton from "./FilterSelectButton";
import CustomRadio from "../CustomRadio";
import { UNAVAILABLE_SPECIALTIES } from "../../tools/utils";
import disableScroll from "disable-scroll";
import { gaEventFilter } from "../../tools/analytics/filterAnalytics";
import i18n from "../../config/i18n";

const RADIO_TEXT_STYLE = {
  fontFamily: "Inter",
  fontSize: 14,
  fontWeight: 700,
  width: "max-content",
};

class FiltersPage extends Component {
  state = {
    submitted: false,
    searchValue: "",
    searchResults: undefined,
    filters: undefined,
    subfilters: undefined,
    medical_specialties: [],
    tags: [],
  };

  componentDidMount() {
    disableScroll.off();
    this.setState({
      medical_specialties: !!this.props.slug
        ? this.props.slug
        : this.props.medical_specialties,
      tags: !!this.props.tags ? [...this.props.tags] : [],
    });

    // NOTE: Check if type prop is ever used
    if (!!this.props.type || !this.props.slug) this.handleFetchSpecialties();
    else this.handleFetchTags();

    /** Scroll Listener for Keyboard Avoidance */
  }

  handleSearch(searchValue) {
    const { filters } = this.state;
    this.setState({ searchValue });

    if (searchValue.length) {
      const options = {
        includeScore: true,
        threshold: 0.25,
        keys: [`translations.${i18n.resolvedLanguage}`],
      };

      const fuse = new Fuse(filters, options);

      let searchResults = fuse.search(searchValue).map((res) => res.item);

      this.setState({ searchResults });
    } else {
      this.setState({ searchResults: undefined });
    }
  }

  handleCancelSearch() {
    this.setState({ searchValue: "" });
  }

  handleFetchSpecialties = async () => {
    this.setState(
      {
        filters: this.props.specialties.medical_specialties.filter(
          (item, index, self) => item.uid !== self[index + 1]?.uid
        ),
      },
      async () => {
        for (let i = 0; i < this.state.medical_specialties.length; i++) {
          const subfilters = await getSpecialtyTags(
            this.state.medical_specialties[i].uid
          );
          this.setState({
            subfilters: !!this.state.subfilters
              ? [...this.state.subfilters, ...subfilters]
              : subfilters,
          });
        }
      }
    );
  };

  handleFetchTags = async (input = undefined) => {
    const filters = await getSpecialtyTags(!!input ? input.uid : this.props.slug);

    this.setState(
      !!input
        ? {
          subfilters: !!this.state.subfilters
            ? // Unique array
            [
              ...this.state.subfilters.filter(
                (item) => item.parent.uid !== input.uid
              ),
              ...filters,
            ]
            : filters,
        }
        : { filters }
    );
  };

  handleSelect = (input) => {
    const pageContainerDOM = document.getElementById(
      "--filters-page-container"
    );

    if (input === "clear") {
      this.setState({
        medical_specialties: [],
        tags: [],
      });
    } else if (!!this.props.slug) {
      if (input === "all") {
        if (this.state.tags.length === this.state.filters.length)
          this.setState({
            tags: [],
          });
        else {
          this.setState({
            tags: [...this.state.filters],
          });

          gaEventFilter({
            name: !!this.props.slug ? "subspecialty" : "specialty",
            value: "all",
          });
        }
      } else {
        if (this.state.tags.map((el) => el.uid).includes(input.uid))
          this.setState({
            tags: [...this.state.tags.filter((el) => el.uid !== input.uid)],
          });
        else {
          this.setState(
            {
              tags: [...this.state.tags, input],
            },
            () => {
              setTimeout(() => {
                pageContainerDOM.offsetParent.scrollTo({
                  top:
                    document.getElementById(`${input.uid}--filter-button-id`)
                      .offsetTop - 200,
                  behavior: "smooth",
                });
              }, 10);
            }
          );

          gaEventFilter({
            name: !!this.props.slug ? "subspecialty" : "specialty",
            value: input.uid,
          });
        }
      }
    } else {
      if (input === "all") {
        if (this.state.medical_specialties.length === this.state.filters.length)
          this.setState({
            medical_specialties: [],
          });
        else
          this.setState({
            medical_specialties: [...this.state.filters],
          });
      } else {
        if (
          this.state.medical_specialties.map((el) => el.uid).includes(input.uid)
        )
          this.setState({
            medical_specialties: [
              ...this.state.medical_specialties.filter(
                (el) => el.uid !== input.uid
              ),
            ],
            tags: [
              ...this.state.tags.filter((el) => el.parent.uid !== input.uid),
            ],
          });
        else
          this.setState(
            {
              medical_specialties: [...this.state.medical_specialties, input],
            },
            () => {
              this.handleFetchTags(input);
              setTimeout(() => {
                pageContainerDOM.offsetParent.scrollTo({
                  top:
                    document.getElementById(`${input.uid}--filter-button-id`)
                      .offsetTop - 200,
                  behavior: "smooth",
                });
              }, 10);
            }
          );
      }
    }
  };

  handleSubselect = async (inputTag, specialty = undefined) => {
    gaEventFilter({
      name: "subspecialty",
      value: inputTag === "all" ? "all" : inputTag.uid,
    });

    if (inputTag === "all") {
      this.setState({
        tags:
          this.state.subfilters.filter((el) => el.parent.uid === specialty.uid)
            .length ===
            this.state.tags.filter((el) => el.parent.uid === specialty.uid).length
            ? [
              ...this.state.tags.filter(
                (el) => el.parent.uid !== specialty.uid
              ),
            ]
            : [
              ...this.state.tags?.filter(
                (el) => el.parent.uid !== specialty?.uid
              ),
              ...this.state.subfilters.filter(
                (el) => el.parent.uid === specialty?.uid
              ),
            ],
      });
    } else {
      if (this.state.tags.map((el) => el.uid).includes(inputTag.uid))
        this.setState({
          tags: [...this.state.tags.filter((el) => el.uid !== inputTag.uid)],
        });
      else
        this.setState({
          tags: [...this.state.tags, inputTag],
        });
    }
  };

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      this.props.onSubmit({
        medical_specialties: !!this.props.slug
          ? this.props.slug
          : [...this.state.medical_specialties],
        tags: [...this.state.tags],
      });
    });
  };

  handleScrollPage = (e) => {
    console.log("scrolling...");
    // e.target[0].blur();
  };

  render() {
    if (!this.state.filters) return <Loader />;
    else {
      const { onClose } = this.props;
      const hideFooter = false;
      const allSelected =
        this.state.filters?.length ===
        this.state[!!this.props.slug ? "tags" : "medical_specialties"]?.length;

      const isFetchSpecialties = !!this.props.type || !this.props.slug;

      const title =
        this.props.title !== "Publications"
          ? this.props.title
          : t(
            isFetchSpecialties
              ? "filterBy.specialties"
              : "filterBy.subspecialties"
          );

      return (
        <PageContainer
          id='--filters-page-container'
          className={isNativeIOS ? "nativeiOS" : ""}
        >
          <HeaderBoxAutoSafe>
            <HeaderLinks><NavBack onClick={onClose} /></HeaderLinks>
            <HeaderTitle>{title}</HeaderTitle>
            <HeaderLinks />
          </HeaderBoxAutoSafe>

          {this.state.filters ? (
            <div>
              <h5>{this.props.title === "Publications"
                ? t("filterBy.selectDisplaySub")
                : (
                  <>
                    {t(isFetchSpecialties ? "filterBy.specialties" : "filterBy.subspecialtiesWithin")} <b>{title}</b>
                  </>
                )
              }
              </h5>
              <CustomSearchInput
                inputRef={this.props.inputDrawerRef}
                placeholder={t("common:action.search")}
                onSearch={this.handleSearch.bind(this)}
                onClear={() =>
                  this.setState({
                    searchResults: undefined,
                    searchValue: "",
                  })
                }
              />
              <Spacer height='8px' />
              <div
                style={{
                  display:
                    this.state.searchValue.length > 0 || !!this.props.slug
                      ? "none"
                      : "block",
                }}
              >
                <h3>{t("common:default")}</h3>
                <div
                  className='selection-rows'
                  style={{
                    padding: "0px 22px",
                  }}
                >
                  <div className='row radios no-border'>
                    <CustomRadio
                      onChange={() => this.handleSelect("all")}
                      checked={allSelected}
                      title={t("common:action.selectAll")}
                      textStyle={RADIO_TEXT_STYLE}
                    />
                    <div
                      className={
                        this.state[
                          !!this.props.slug ? "tags" : "medical_specialties"
                        ]?.length
                          ? "clear-button allSelected"
                          : "clear-button"
                      }
                      onClick={() => this.handleSelect("clear")}
                    >
                      <CustomIcon
                        color={
                          this.state[
                            !!this.props.slug ? "tags" : "medical_specialties"
                          ].length
                            ? "#FF4300"
                            : "#D2DCE2"
                        }
                        scale={0.9}
                        iconName='times-radio'
                      />
                      <p>{t("Clear all")}</p>
                    </div>
                  </div>
                </div>
                <hr style={{ margin: "16px 0" }} />
              </div>
              <h3>{t(isFetchSpecialties ? "common:specialties" : "profile.subspecialties")}</h3>
              <div className='selection-rows'>
                {this.state[!!this.props.slug ? "tags" : "medical_specialties"]
                  ?.reverse()
                  .sort((a, b) =>
                    getItemTranslation(a).localeCompare(getItemTranslation(b))
                  )
                  .filter((el) =>
                    !!this.state.searchResults
                      ? this.state.searchResults?.find(
                        (item) => item._id === el._id
                      )
                      : true
                  )
                  .map((item, index) => (
                    <FilterSelectButton
                      key={item._id + index + "--selectedFilterButton"}
                      {...this.state}
                      specialty={item}
                      handleSelect={this.handleSelect}
                      handleSubselect={this.handleSubselect}
                      slug={this.props.slug}
                      hideTags={this.props.hideTags}
                    />
                  ))}

                {(!!this.state.searchResults
                  ? this.state.searchResults
                  : this.state.filters
                )
                  ?.filter(
                    (el) =>
                      !this.state[
                        !!this.props.slug ? "tags" : "medical_specialties"
                      ].some((item) => item._id === el._id)
                  )
                  .sort((a, b) =>
                    getItemTranslation(a).localeCompare(getItemTranslation(b))
                  )
                  .filter(
                    (el) =>
                      !UNAVAILABLE_SPECIALTIES.includes(getItemTranslation(el, SupportedLanguagesEnum.EN))
                  )
                  .map((item, index) => (
                    <FilterSelectButton
                      key={item._id + index + "--filterButton"}
                      {...this.state}
                      specialty={item}
                      handleSelect={this.handleSelect}
                      handleSubselect={this.handleSubselect}
                      slug={this.props.slug}
                      hideTags={this.props.hideTags}
                    />
                  ))}
              </div>
            </div>
          ) : (
            <Loader />
          )}
          <div className={`footer ${hideFooter ? "hide" : ""}`}>
            <CustomButton
              className={this.state.submitted ? "loading disabled" : ""}
              onClick={this.handleSubmit.bind(this)}
            >
              {t("Save")}
            </CustomButton>
          </div>
        </PageContainer>
      );
    }
  }
}

function mapState(state) {
  const user = state.user;
  const specialties = state.specialties;
  return {
    user,
    specialties,
  };
}

const mapDispatch = {
  getUser: userApi.endpoints.getUser.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(FiltersPage);

const PageContainer = styled(StyledOnboarding)`
  padding: 0 0 100px;
  background: #ffffff;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;

  .--custom-search {
    margin: auto 21px;
  }

  h5 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #000000;
    text-align: left;
    margin: 32px 21px;
    padding: 0;
  }

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 100%;
    color: #ce0868;
    margin: 32px 21px 0px;
  }

  .row {
    &.no-border {
      border: none;
    }

    &.radios {
      justify-content: space-between;
      align-items: center;
    }

    .clear-button {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-family: "Inter";
        font-weight: 700;
        font-size: 14px;
        color: #d2dce2;
        margin: 0;
      }

      &.allSelected {
        p {
          fill: #ff4300 !important;
          color: #ff4300;
        }
      }
    }
  }

  .content-section.with-tags {
    padding: 0 0 24px;
    border-bottom: 1px solid #212121;
  }

  .tag.block {
    margin-bottom: 8px;

    &.selected {
      color: #ce0868;
      background: #fae6f0;
    }
  }
`;
