import { ArticleModel } from "../domains/article/article.types";
import { UserModel } from "../domains/user/user.types";

export interface IImage {
  _id: string;
  path: string;
  url: string;
  legend?: string;
}

export interface IComment {
  article: ArticleModel;
  message: string;
  user: UserModel;
  meta: {
    creationDate: Date;
    lastModified: Date;
  };
}

export enum SupportedLanguagesEnum {
  EN = "en",
  FR = "fr",
  IT = "it",
  ES = "es",
}

export enum OrganisationUid {
  JUISCI = "juisci",
  BIOCODEX = "biocodex-rd",
  // SFRO = "sfro",
  // SFR = "sfr",
  // ROYAL_CANIN = "royal-canin",
  // SPILF = "spilf",
  // MGEN = "mgen",
  // OPELLA = "opella-body-pain",
  // ESR = "esr",
  TAKEDA = "takeda-lymphoma-app",
  // UCB = "ucb-rare-diseases",
  // ORPEA = "orpea-eldery-app",
  // SANOFI_AUDIOROOM = "sanoficoughcoldfluaudioroom",
}

export enum SoundEffect {
  LIKE = "like",
  SAVE = "save",
  SHARE = "share",
}

export enum HapticEffect {
  SELECTION = "selection",
  SUCCESS = "success",
  BLENDER = "blender",
  ERROR = "error",
}

export enum PlaylistAction {
  ADD = "add",
  REMOVE = "remove",
  NONE = "none",
}

// Scientific publications, Medical guidelines, Editorial content, Videos, Podcasts,
export enum PreferredContent {
  SCIENTIFIC_PUBLICATIONS = "Scientific publications",
  MEDICAL_GUIDELINES = "Medical guidelines",
  EDITORIAL_CONTENT = "Editorial content",
  VIDEOS = "Videos",
  PODCASTS = "Podcasts",
}
