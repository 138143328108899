export enum SearchCategory {
  ALL = "all",
  ARTICLES = "articles",
  JOURNALS = "journals",
  PLAYLISTS = "playlists",
  USERS = "users",
  VIDEOS = "videos",
  NECTARS = "nectars",
}

export enum SearchFilterScoringEnum {
  MOST_RELEVANT = "relevant",
  MOST_RECENT = "recent",
  MOST_VIEWED = "viewed",
}

export enum SearchFilterPublicationDateEnum {
  NO_FILTER,
  LESS_THAN_1_YEAR,
  LESS_THAN_2_YEARS,
  LESS_THAN_3_YEARS,
  MORE_THAN_3_YEARS,
}

export enum SearchFilterPublicationTypeEnum {
  NO_FILTER = "None",
  CASE_REPORT = "Case Report",
  CLINICAL_STUDY = "Clinical study",
  COMMENTARY = "Commentary",
  GUIDELINE = "Guidelines",
  LETTER_EDITORIAL = "Letter/Editorial",
  META_ANALYSIS = "Meta-analysis",
  ORIGINAL_RESEARCH = "Original research",
  PERSPECTIVE = "Perspective",
  REVIEW = "Review",
  SHORT_COMMUNICATION = "Short communication",
  STUDY_PROTOCOL = "Study Protocol",
  SYSTEMATIC_REVIEW_META_ANALYSIS = "Systematic review and meta-analysis",
  TECHNICAL_REPORT = "Technical report",
}

export interface SearchFiltersModel {
  scoring: SearchFilterScoringEnum;
  publicationDate: SearchFilterPublicationDateEnum;
  publicationType: SearchFilterPublicationTypeEnum;
  impactFactor: number;
}

export const defaultSearchFilters: SearchFiltersModel = {
  scoring: SearchFilterScoringEnum.MOST_RELEVANT,
  publicationDate: SearchFilterPublicationDateEnum.NO_FILTER,
  publicationType: SearchFilterPublicationTypeEnum.NO_FILTER,
  impactFactor: 0,
};

export interface SearchFacetsModel {
  publicationDate?: {};
  publicationType?: {};
  impactFactor?: {};
}

export const defaultSearchFacets: SearchFacetsModel = {
  publicationDate: {},
  publicationType: {},
  impactFactor: {},
};
