import { useHistory } from "react-router-dom";
import { VideoFormat, VideoModel } from "../../video/video.types";
import { useCurrentUser } from "./useCurrentUser";
import { shareContent } from "../endpoints/shareContent";
import { preflightUser } from "../utils/preflightUser";
import { SupportedLanguagesEnum } from "../../../interfaces";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";
import { isAndroid } from "react-device-detect";
import { androidShare } from "../../../tools/android";
import i18n from "../../../config/i18n";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { gaEventVideoShare } from "../../../tools/analytics/videoAnalytics";

export function useShareVideo(video: VideoModel | null, analyticsListName: AnalyticsItemList) {
  const history = useHistory();
  const { currentUser } = useCurrentUser();

  return async function () {
    try {
      if (!video) throw new Error("Invalid video.");

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.shareContent"),
      });
      if (isRedirected) return;

      const url = `
        ${window.location.origin}/${video.videoFormat === VideoFormat.STORY
          ? "video/story"
          : "video"
        }/${video.slug}?shared=${currentUser?.uid ?? null}
      `;

      const publisherName =
        video.publisher === "journal"
          ? video.journal?.name
          : video.publisher === "congress"
            ? video.congress?.name
            : video.company?.name;

      // TODO: localize
      const text = i18n.resolvedLanguage === SupportedLanguagesEnum.FR
        ? `Je viens de regarder cette vidéo qui pourrait t'intéresser : ${video.title} par ${publisherName}.`
        : `I’ve just watched this video you may be interested in: ${video.title} by ${publisherName}.\n`;

      if (isAndroid) {
        return androidShare(url, text);
      }

      if (!navigator.share) {
        displayToast(t("toast.fail.browser"));
        return;
      }

      await navigator.share({
        title: document.title,
        text,
        url,
      });

      await shareContent(video.slug);

      gaEventVideoShare({
        video,
        listName: analyticsListName,
      });

      displayToast(t("you have shared a video"), "success");
    } catch (error) {
      console.error("Couldn't share video.", error);
      displayToast(t("error:default"));
    }
  };
}