import { SpecialtyModel, TagModel } from "../../domains/app/app.types";
import { gaPushEvent } from "./gaPushEvent";

enum Event {
  FILTER = "filter",
  SET_FILTER = "set_filter",
}

interface FilterPayload {
  name: string;
  value: string;
}

interface SpecialtyItemPayload {
  specialty: SpecialtyModel;
  tags: TagModel[];
}

interface SpecialtyItemListPayload {
  specialties: SpecialtyModel[];
  tags: TagModel[];
}

function convertSpecialtyToItemData(payload: SpecialtyItemPayload) {
  const { specialty, tags } = payload;
  const subSpecialties = tags.filter((tag: TagModel) => {
    return tag.parent.uid === specialty.uid;
  }).map(tag => tag.uid).join(",");

  return {
    item_id: specialty._id,
    item_name: specialty.uid,
    item_category: subSpecialties.length ? subSpecialties : specialty.uid,
    item_list_name: "filters", // NOTE: Static.
    item_list_id: "filters", // NOTE: Static.
  };
}

export function gaEventSetFilter(payload: SpecialtyItemListPayload) {
  gaPushEvent(
    Event.SET_FILTER,
    {
      ecommerce: {
        items: payload.specialties.map((specialty) => {
          return convertSpecialtyToItemData({
            specialty,
            tags: payload.tags,
          });
        }),
      },
    },
  );
}

export function gaEventFilter(payload: FilterPayload) {
  gaPushEvent(
    Event.FILTER,
    {
      filter: {
        filter_name: payload.name,
        filter_value: payload.value,
      }
    },
  );
}
