import { Component } from "react";
import CustomSheet from "../CustomSheet";
import JuisciPipe from "../../assets/core/pipe-icon.svg";
import { CustomButton, Spacer } from "../global";
import { t } from "i18next";
import { RemoveScroll } from "react-remove-scroll";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import i18n from "../../config/i18n";
import { CustomInput } from "../CustomInput";
import CustomIcon from "../CustomIcon";
import { Checkbox } from "antd-mobile";
import { createPlaylist } from "../../domains/playlist/endpoints/createPlaylist";
import { addArticleToPlaylist } from "../../domains/playlist/endpoints/addArticleToPlaylist";
import { displayToast } from "../app/AppToast";
import { isNativeIOS } from "../../tools/ios";
import PreviewCard from "../profile/PreviewCard";
import { saveContentToPlaylists } from "../../services/contentApi";
import { store } from "../../redux";
import { SupportedLanguagesEnum } from "../../interfaces";

const AgreeItem = Checkbox.AgreeItem;

class PlaylistSheet extends Component {
  state = {
    // Step 0. No playlist
    // Step 1. Create playlist
    // Step 2. Add to playlist
    step: 0,
    playlistTitle: "",
    selectedPlaylists: [],
    enableBackButton: false,
    confirmInProgress: false,
  };

  componentDidMount() {
    // If user has at least one playlist -> Step 2
    if (this.props?.playlists?.length) {
      let selectedPlaylists = [...this.state.selectedPlaylists];
      this.props?.playlists?.forEach(({ playlist, _id, playlistContent }) => {
        if (
          playlist?.find(
            (article) => article._id === this.props?.article?._id
          ) ||
          playlistContent?.find(
            (videoId) => videoId === this.props?.article?._id
          )
        ) {
          selectedPlaylists.push(_id);
        }
      });

      this.setState({ selectedPlaylists, step: 2, enableBackButton: true });
    }
  }

  // Add playlist to selected list
  handleAddToList(title) {
    const tmp = this.state.selectedPlaylists;

    if (tmp.includes(title)) {
      const index = tmp.indexOf(title);
      tmp.splice(index, 1);
    } else {
      tmp.push(title);
    }

    this.setState({ selectedPlaylists: tmp });
  }

  // Creating a new playlist & add content
  async handleCreatePlaylist() {
    this.setState({
      creationInProgress: true,
    });

    if (!this.state.playlistTitle.length) {
      return displayToast(t("toast.fail.playlist_name"), "error");
    }

    // Create playlist
    const newPlaylist = await createPlaylist({
      title: this.state.playlistTitle,
    });

    // Handle error
    if (!!newPlaylist.error) {
      this.setState({
        enableBackButton: true,
        creationInProgress: false,
      });
      return displayToast(t("This playlist title already exists"), "error");
    }

    this.props.onClose();

    // Handle videos
    if (this.props.type === "video") {
      this.props.onConfirmCreatePlaylist(newPlaylist);
      return;
    }

    // Add article to the new playlist
    await addArticleToPlaylist(newPlaylist._id, this.props.article?.slug);

    this.setState({
      playlistTitle: "",
      enableBackButton: true,
      creationInProgress: false,
    });

    this.props.onComplete &&
      this.props.onComplete({
        contentIsInPlaylist: true,
      });

    displayToast(t("toast.success.playlist_create"));

    // Update playlist data
    await this.props.getPlaylists();
  }

  // Confirm and add article to selected user's playlists
  handleConfirmList = async () => {
    this.setState({ confirmInProgress: true });

    const {
      contentIsInPlaylist,
      contentWasAdded,
      contentWasRemoved,
      playlistsToAdd,
      playlistsToRemove,
    } = saveContentToPlaylists(
      this.props.article,
      this.state.selectedPlaylists,
      this.props.analyticsListName
    );

    /** Function complete callback */
    if (contentWasAdded || contentWasRemoved) {
      this.props.onComplete &&
        this.props.onComplete({
          contentIsInPlaylist,
          playlistsToAdd,
          playlistsToRemove,
        });
    }

    /** Close callback */
    this.props.onClose();

    this.setState({ confirmInProgress: false });
  };

  render() {
    const firstname = store.getState().user?.user?.firstname;

    if (!this.props.showSheet) return null;

    switch (this.state.step) {
      // Create playlist
      case 1:
        return (
          <CustomSheet
            show={this.state.step === 1}
            title={t("playlist.create.title")}
            onChange={this.props.onClose}
            backContent={
              <div
                onClick={() => this.setState({ step: 2 })}
                style={{
                  marginBottom: -15,
                  marginLeft: 5,
                  width: 35,
                  display:
                    this.state.enableBackButton && this.props?.playlists?.length
                      ? "block"
                      : "none",
                }}
              >
                <CustomIcon iconName='back-arrow' />
              </div>
            }
            bottomContent={
              <>
                <CustomButton
                  onClick={() => this.handleCreatePlaylist()}
                  className={
                    this.state.creationInProgress
                      ? "loading disabled"
                      : "purple-gradient"
                  }
                  style={{ textTransform: "capitalize" }}
                >
                  {t("common:action.save")}
                </CustomButton>
                {isNativeIOS && <Spacer height='5px' />}
              </>
            }
          >
            <div style={{ overflow: "hidden" }}>
              <Spacer />
              <CustomInput
                onInput={(e) =>
                  this.setState({ playlistTitle: e.target.value })
                }
                placeholder={t("playlist.create.name")}
              />
              <Spacer />
            </div>
          </CustomSheet>
        );

      // Add to playlist
      case 2:
        const isPlaylistChecked = (playlist) =>
          this.state.selectedPlaylists.includes(playlist._id);

        return (
          <CustomSheet
            show={this.state.step === 2}
            title={t("Add to playlist")}
            onChange={this.props.onClose}
            bottomContent={
              <>
                <CustomButton
                  onClick={() => this.setState({ step: 1 })}
                  style={{ background: "#000", color: "#fff" }}
                >
                  {t("playlist.create.title")}
                </CustomButton>
                <CustomButton
                  onClick={() =>
                    this.props.type === "video"
                      ? this.props.onConfirm(this.state.selectedPlaylists)
                      : this.handleConfirmList()
                  }
                  className={
                    this.state.confirmInProgress
                      ? "loading disabled"
                      : "purple-gradient"
                  }
                  style={{ textTransform: "capitalize", marginTop: 16 }}
                >
                  {t("common:action.save")}
                </CustomButton>
                {isNativeIOS && <Spacer height='5px' />}
              </>
            }
          >
            <div style={{ paddingBottom: "100px" }}>
              <PreviewCard content={this.props.article} />
              <Spacer height='10px' />
              <h5>{t("Add this publication to playlist")}</h5>
              {this.props?.playlists &&
                this.props?.playlists?.map((playlist) => {
                  return (
                    <div key={playlist._id}>
                      <AgreeItem
                        onChange={() => this.handleAddToList(playlist._id)}
                        style={{ marginLeft: 0, paddingLeft: 0 }}
                        checked={isPlaylistChecked(playlist)}
                      >
                        <span
                          style={{
                            color: "#212121",
                            fontWeight: 600,
                            fontSize: 18,
                            fontFamily: "Inter",
                          }}
                        >
                          {playlist.title}
                        </span>
                      </AgreeItem>
                    </div>
                  );
                })}
            </div>
          </CustomSheet>
        );

      // No playlist
      default:
        return (
          <CustomSheet
            onChange={this.props.onClose}
            title={t("profile.create_playlist")}
            show={this.state.step === 0}
            bottomContent={
              <>
                <CustomButton
                  style={{ background: "black", color: "white" }}
                  onClick={() => this.setState({ step: 1 })}
                >
                  {t("profile.create_playlist")}
                </CustomButton>
                {isNativeIOS && <Spacer height='5px' />}
              </>
            }
          >
            <div style={{ overflow: "hidden" }}>
              <RemoveScroll />
              <RemoveScrollBar />
              <center>
                <img
                  style={{ marginTop: "20px" }}
                  src={JuisciPipe}
                  alt=''
                  height='100px'
                />
                <p style={{ fontSize: 15, margin: "20px 32px" }}>
                  {i18n.resolvedLanguage === SupportedLanguagesEnum.FR ? (
                    <>
                      <b>Bonjour {firstname ?? "docteur"}</b>, il semble que
                      vous n'ayez pas encore créé de liste de lecture.
                      <br />
                      <br />
                      Créez-en une dès maintenant pour consulter les articles
                      par thèmes ou spécialités quand vous le souhaitez.
                    </>
                  ) : (
                    <>
                      <b>Hi {firstname ?? "doc"}</b>, it seems that you haven’t
                      created any playlist yet.
                      <br />
                      <br />
                      Create one right now to consult papers by topics or
                      specialties any time you want.
                    </>
                  )}
                </p>
              </center>
            </div>
          </CustomSheet>
        );
    }
  }
}

export default PlaylistSheet;
