import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../redux";
import { useRoomContext } from "../../app/contexts/room.context";
import { UserRoomModel } from "../../user/user.types";
import { getRoomById } from "../endpoints/getRoomById";
import { useCurrentUser } from "../../user/hooks/useCurrentUser";
import { useLazyGetUserRoomsQuery } from "../../user/endpoints/getUserRooms";
import { enterRoom, exitRoom } from "../../../redux/user/user.reducer";

// NOTE: Temporary check until we get a true access guard on rooms.
export function useRoomPreflightTemp(roomId: string) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { rooms } = useCurrentUser();
  const { isInRoom, currentRoomId, setCurrentRoom } = useRoomContext();
  const activeRoom = rooms.find((room: UserRoomModel) => room._id === currentRoomId);
  const [roomData, setRoomData] = useState<UserRoomModel | null>(activeRoom ?? null);
  const [isLoadingRoom, setLoadingRoom] = useState(!activeRoom);
  const [getUserRooms] = useLazyGetUserRoomsQuery();

  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes(roomId)) {
        dispatch(exitRoom());
      }
    }
  }, []);

  useEffect(() => {
    if (isInRoom) setLoadingRoom(false);
  }, [isInRoom]);

  async function preflightUserRoom() {
    try {
      const data = roomData ?? await getRoomById(roomId);
      const userRooms = await getUserRooms().unwrap();
      const canAccess = data.isPublic || userRooms.some((room: UserRoomModel) => room._id === roomId);
      if (!canAccess) {
        throw new Error("Room not in user's rooms.");
      }
      setRoomData(data);
      dispatch(enterRoom(data as UserRoomModel));
      setCurrentRoom(data);
    } catch (error) {
      throw error;
    }
  }

  return {
    isLoadingRoom,
    roomData,
    preflightUserRoom,
  };
}
