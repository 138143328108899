import { ReactChildren, ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { DebugLogger } from "../utils/debugLogger";
import { UserRoomModel } from "../../user/user.types";
import { SupportedLanguagesEnum } from "../../../interfaces";
import { DEFAULT_LANGUAGE } from "../../../config/i18n";

const debug = new DebugLogger("RoomContext");

const PUBLIC_PAGES_ACCESSIBLE_FROM_ROOMS = [
  "/post",
  "/nectar",
  "/infographic",
  "/video/story",
  "/company",
  "/journal",
  "/playlist/public",
  "/discovery/nectars",
];

export const RoomContext = createContext<{
  isInRoom: boolean;
  currentRoom: UserRoomModel | null;
  currentRoomId: string | null;
  allowedLanguages: SupportedLanguagesEnum[];
  setCurrentRoom: Function;
  leaveRoom: Function;
}>({
  isInRoom: false,
  currentRoom: null,
  currentRoomId: null,
  allowedLanguages: [],
  setCurrentRoom: () => null,
  leaveRoom: () => null,
});

export function useRoomContext() {
  const context = useContext(RoomContext);
  if (context === undefined) {
    throw new Error("Missing provider for room context");
  }
  return context;
}

export function RoomProvider({ children }: {
  children: ReactNode | ReactChildren;
}) {
  const location = useLocation();
  const [currentRoom, setCurrentRoom] = useState<UserRoomModel | null>(null);
  const roomId = currentRoom?._id ?? null;
  const isInRoom = !!currentRoom;
  const memRoomIdRef = useRef<string | null>(roomId);
  // Derived allowed languages in room
  const allowedLanguages = (
    currentRoom?.allowedLanguages && currentRoom?.allowedLanguages?.length > 0
      ? currentRoom?.allowedLanguages
      : [DEFAULT_LANGUAGE]
  ) ?? [];

  useEffect(() => {
    // Leave room when room ID not in the pathname
    if (roomId && !location.pathname.includes(roomId)) {
      const isAccessibleContentPage = PUBLIC_PAGES_ACCESSIBLE_FROM_ROOMS.some((pathPrefix) => {
        return location.pathname.startsWith(pathPrefix);
      });
      if (!isAccessibleContentPage) {
        leaveRoom();
      }
    }
  }, [location.pathname])

  useEffect(() => {
    if (roomId !== memRoomIdRef.current) {
      if (isInRoom) {
        debug.log("Entered room", { isInRoom, name: currentRoom.name, roomId, currentRoom });
        memRoomIdRef.current = roomId;
        if (roomId) localStorage.setItem("currentRoomId", roomId.toString());

        if (!currentRoom?.allowedLanguages.length) {
          console.warn("No available languages in this room, using default", {
            roomId,
            allowedLanguages: currentRoom?.allowedLanguages,
          });
        }
      } else {
        debug.log("Left room", { isInRoom, roomId, currentRoom });
        memRoomIdRef.current = roomId;
        localStorage.removeItem("currentRoomId");
      }
    }
  }, [currentRoom]);

  function leaveRoom() {
    setCurrentRoom(null);
  }

  return (
    <RoomContext.Provider value={{
      isInRoom,
      currentRoom,
      currentRoomId: roomId,
      allowedLanguages,
      setCurrentRoom,
      leaveRoom,
    }}>
      {children}
    </RoomContext.Provider>
  );
}
