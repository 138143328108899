import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import disableScroll from "disable-scroll";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderTitle,
  NavBack,
  NavOptions,
} from "../../components/app/headers/layout";
import styled from "styled-components";
import Loader from "../../components/Loader";
import { getContentFromSlug } from "../../domains/content/endpoints/getContentFromSlug";
import { InfographicModel } from "../../interfaces/infographic.types";
import { useLangContext } from "../../domains/app/contexts/lang.context";

export default function InfographicPage() {
  const location = useLocation<{ infographic: any }>();
  const params = useParams<{ slug: string }>();
  const { t } = useLangContext();
  const [infographic, setInfographic] = useState<InfographicModel | null>(null);
  const [infographicLoaded, setInfographicLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    disableScroll.off();
    fetchInfographic();
  }, []);

  async function fetchInfographic() {
    try {
      let loadedInfographic =
        location.state?.infographic ?? (await getContentFromSlug(params.slug));
      setInfographic(loadedInfographic);
    } catch (err) {
      console.error("Error fetching infographic:", err);
      setError("Failed to load infographic");
    }
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!infographic) {
    return <Loader />;
  }

  return (
    <>
      <HeaderBoxAutoSafe>
        <HeaderLinks>
          <NavBack />
        </HeaderLinks>
        <HeaderTitle>{t("Infographic")}</HeaderTitle>
        <HeaderLinks>{/* <NavOptions /> */}</HeaderLinks>
      </HeaderBoxAutoSafe>
      <Container>
        {!infographicLoaded ?? <Loader background="#858585" />}
        <img
          src={infographic.image.url}
          alt={infographic.title || "infographic"}
          loading="lazy"
          onLoad={() => setInfographicLoaded(true)}
        />
      </Container>
    </>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  display: flex;

  img {
    width: 100%;
    height: auto;
  }
`;
