import { useHistory } from "react-router-dom";
import { useCurrentUser } from "./useCurrentUser";
import { NectarModel } from "../../nectar/nectar.types";
import { PlaylistModel } from "../../playlist/playlist.types";
import { useLazyGetPlaylistsQuery } from "../../../redux/user/user.service";
import { addContentToPlaylist } from "../../playlist/endpoints/addContentToPlaylist";
import { removeContentFromPlaylist } from "../../playlist/endpoints/removeContentFromPlaylist";
import { preflightUser } from "../utils/preflightUser";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { gaEventNectarSave } from "../../../tools/analytics/nectarAnalytics";
import { SoundEffect } from "../../../interfaces";
import { iosPlaySound } from "../../../tools/ios";

export function useSaveNectar(nectar: NectarModel | null, analyticsListName: AnalyticsItemList) {
  const history = useHistory();
  const { playlists } = useCurrentUser();
  const isSaved = playlists && playlists?.some((playlist: PlaylistModel) => {
    return playlist.playlistContent.some((content: any) => {
      return content._id === nectar?._id
    })
  });
  const [getPlaylists] = useLazyGetPlaylistsQuery();

  async function toggleSaveToPlaylists({ add, remove }: {
    add: string[];
    remove: string[];
  }) {
    try {
      if (!nectar) throw new Error("Invalid nectar.");

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.saveContent"),
      });
      if (isRedirected) return;

      await Promise.all(add.map(playlistId => addContentToPlaylist(playlistId, nectar._id)));
      await Promise.all(remove.map(playlistId => removeContentFromPlaylist(playlistId, nectar._id)));
      // Refresh user playlists
      await getPlaylists({})
        .unwrap()
        .catch((error) => {
          console.error("Couldn't get user playlists.", error);
        });

      if (add.length) {
        iosPlaySound(SoundEffect.SAVE);
        gaEventNectarSave({
          nectar,
          listName: analyticsListName,
        });
      }

      displayToast(t(add.length > 0
        ? "Nectar saved to your playlist"
        : "this nectar has been removed from your playlist"
      ));
    } catch (error) {
      console.error("Couldn't save/unsave nectar.", error);
      displayToast(t("error:default"));
    }
  }

  return {
    isSaved,
    toggleSaveToPlaylists,
  };
}

