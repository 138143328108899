import { SpecialtyModel } from "../../domains/app/app.types";
import { NectarModel } from "../../domains/nectar/nectar.types";
import { AnalyticsItemCategory, AnalyticsItemVariant } from "./analytics.types";
import { gaEventSelectItem, gaEventViewItem, gaEventViewItemList } from "./analyticsEvents";
import { gaPushEvent } from "./gaPushEvent";

enum Event {
  NECTAR_LIKE = "nectar_like",
  NECTAR_SAVE = "nectar_save",
  NECTAR_SHARE = "nectar_share",
}

interface NectarItemPayload {
  nectar: NectarModel;
  listName: string;
}

interface NectarItemListPayload {
  nectars: NectarModel[];
  listName: string;
}

function convertNectarToItemData(payload: NectarItemPayload) {
  const { nectar, listName } = payload;
  return {
    item_id: nectar._id,
    item_name: nectar.title,
    item_brand: null, // TODO: Check what to put here. Nectars have no organisations.
    item_category: nectar.medical_specialties?.map((spe: SpecialtyModel) => {
      return spe.uid;
    }).join(","),
    item_category2: AnalyticsItemCategory.STANDARD,
    item_variant: AnalyticsItemVariant.NECTAR,
    item_list_name: listName,
  };
}

export function gaEventSelectItemNectar(payload: NectarItemPayload) {
  gaEventSelectItem(convertNectarToItemData(payload));
}

export function gaEventViewItemNectar(payload: NectarItemPayload) {
  gaEventViewItem(convertNectarToItemData(payload));
}

export function gaEventViewItemListNectar(payload: NectarItemListPayload) {
  gaEventViewItemList(payload.nectars.map((nectar) => {
    return convertNectarToItemData({
      nectar,
      listName: payload.listName,
    });
  }));
}

export function gaEventNectarLike(payload: NectarItemPayload) {
  gaPushEvent(
    Event.NECTAR_LIKE,
    {
      ecommerce: {
        items: [convertNectarToItemData(payload)],
      },
    },
  );
}

export function gaEventNectarSave(payload: NectarItemPayload) {
  gaPushEvent(
    Event.NECTAR_SAVE,
    {
      ecommerce: {
        items: [convertNectarToItemData(payload)],
      },
    },
  );
}

export function gaEventNectarShare(payload: NectarItemPayload) {
  gaPushEvent(
    Event.NECTAR_SHARE,
    {
      ecommerce: {
        items: [convertNectarToItemData(payload)],
      },
    },
  );
}
