import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useCurrentUser } from "../../../domains/user/hooks/useCurrentUser";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { SpecialtyModel } from "../../../domains/app/app.types";
import { ArticleModel } from "../../../domains/article/article.types";
import { useLazyGetArticlesListQuery } from "../../../domains/article/endpoints/getArticlesList";
import { getItemTranslation } from "../../../domains/app/utils/getItemTranslation";
import HomeCarousel from "./HomeCarousel";
import CarouselIndicator from "../../app/CarouselIndicator";
import { CustomButton } from "../../global";
import ArticleCard from "../../cards/ArticleCard";
import CustomIcon from "../../CustomIcon";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import {
  memorizeArticlesList,
  memorizeMainSpecialtyArticles,
  memorizeSecondarySpecialtyArticles,
  memorizeSwipeList,
  setSwipeIndex,
} from "../../../domains/article/article.reducer";
import { ReactComponent as PublicationIcon } from "../../../assets/icons/home_publication_icon.svg";
import styled from "styled-components";
import SkeletonCard from "../../skeleton/SkeletonCard";

export default function HomeArticleCarousel({
  specialty,
  isMainSpecialty = false,
}: {
  specialty: SpecialtyModel;
  isMainSpecialty?: boolean;
}) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentUser } = useCurrentUser();
  const { activeLang, t } = useLangContext();
  const cache = useAppSelector((state) => state.articles.cache);
  // NOTE: Used for testing purposes. Not connected to store.
  const [localArticles, setLocalArticles] = useState<ArticleModel[] | []>([]);
  const [getArticlesList, { isLoading }] = useLazyGetArticlesListQuery();
  const mountRef = useRef(false);
  const [isMounted, setMounted] = [
    !!mountRef.current,
    () => (mountRef.current = true),
  ];
  // Derived
  const articles: ArticleModel[] = !specialty
    ? cache.list
    : specialty?.uid === currentUser?.main_specialty?.uid
      ? cache.mainSpecialty
      : specialty?.uid === currentUser.medical_specialties[0].uid
        ? cache.secondarySpecialty
        : localArticles;

  useEffect(() => {
    if (!isMounted) {
      if (!articles.length) refreshArticles();
      setMounted();
    }
  }, []);

  useEffect(() => {
    if (isMounted) refreshArticles();
  }, [activeLang, specialty]);

  async function refreshArticles() {
    try {
      const queryParams: any = {
        limit: 20,
        offset: 0,
        language: activeLang,
      };
      if (specialty) queryParams.medicalSpecialties = specialty.uid;

      getArticlesList(queryParams)
        .unwrap()
        .then(({ docs }) => {
          setLocalArticles(docs);
          refreshCache(docs);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't load carousel articles.", error);
    }
  }

  function refreshCache(docs: ArticleModel[]) {
    if (!specialty) {
      dispatch(memorizeArticlesList(docs));
    }
    if (!!currentUser) {
      if (specialty?.uid === currentUser?.main_specialty?.uid) {
        dispatch(memorizeMainSpecialtyArticles(docs));
      }
      if (specialty?.uid === currentUser?.medical_specialties[0]?.uid) {
        dispatch(memorizeSecondarySpecialtyArticles(docs));
      }
    }
  }

  // NOTE: Both of the conditions are necessary here to avoid a glitch with some specialties
  // when swiping.
  // NOTE: Leave this in case we get the glitch back.
  // if (!articles.length && isLoading) return <Loader loaderOnly />;

  return (
    <Wrapper className={` ${isMainSpecialty ? " primary" : ""}`}>
      <Header>
        <div className='title'>
          <PublicationIcon />
          <h3>
            {isMainSpecialty
              ? t("home:articles.freshlySqueezed") + " "
              : t("home:articles.whatsNew") + " "}
            <br />
            <span>{specialty ? getItemTranslation(specialty) : t("All")}</span>
          </h3>
        </div>
        <Link
          to={
            specialty
              ? {
                pathname: `/discovery/publications/${specialty.uid}`,
                state: { specialty },
              }
              : "/discovery/publications"
          }
        >
          <span>{t("common:action.seeAll")}</span>
        </Link>
      </Header>

      {/* NOTE: See notes above. */}
      {(!articles.length && isLoading) ? (
        <SkeletonCard />
      ) : (
        <HomeCarousel
          renderIndicator={(_onClickHandler: any, isSelected: boolean) => {
            return <CarouselIndicator active={isSelected} />;
          }}
        >
          {articles.slice(0, 5).map((article, index) => {
            return (
              <ArticleCard
                key={article._id}
                article={article}
                variant='yellow'
                analyticsListName={
                  isMainSpecialty
                    ? AnalyticsItemList.DISCOVERY_MAIN_CAROUSEL
                    : AnalyticsItemList.DISCOVERY_SECOND_CAROUSEL
                }
                onClick={() => {
                  dispatch(memorizeSwipeList(articles));
                  dispatch(setSwipeIndex(index));
                }}
              />
            );
          })}
          <SeeMoreWrapper
            onClick={() =>
              history.push(
                specialty
                  ? {
                    pathname: `/discovery/publications/${specialty.uid}`,
                    state: { specialty },
                  }
                  : "/discovery/publications"
              )
            }
          >
            <CustomIcon iconName='invite_down_arrow' />
            <p>
              {t("invite_card.articles")}{" "}
              <span>{specialty ? getItemTranslation(specialty) : t("All")}</span>
            </p>
            <CustomButton className='pink'>{t("Lets go")}</CustomButton>
          </SeeMoreWrapper>
        </HomeCarousel>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 21px 8px 21px;
  .title {
    display: flex;
    gap: 6px;
  }
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;
  }
  span {
    color: #ce0868;
  }
  a {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #ce0868;
    margin: 0;
  }
`;

const SeeMoreWrapper = styled.div`
  box-sizing: border-box;
  flex: 1;
  background: #f7f6f2;
  border-radius: 15px;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;
