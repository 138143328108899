import { Component } from "react";
import { preflightUser } from "../domains/user/utils/preflightUser";
import { getItemTranslation } from "../domains/app/utils/getItemTranslation";
import styled from "styled-components";
import { Col, Row } from "react-grid-system";
import { withRouter } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import CustomIcon from "./CustomIcon";
import { SpecialtyTag, Spacer } from "./global";
import ShareBtn from "../assets/icons/share-btn.svg";
import { displayToast } from "./app/AppToast";
import { connect } from "react-redux";
import { userApi } from "../redux/user/user.service";
import { renderDate } from "../domains/app/utils/renderDate";
import { t } from "i18next";
import disableScroll from "disable-scroll";
import { gaEventVideoShare } from "../tools/analytics/videoAnalytics";
import i18n from "../config/i18n";
import { androidShare } from "../tools/android";
import { isAndroid } from "react-device-detect";
import JuisciBacground from "../assets/images/mobile-pattern.svg";
import { iosPlaySound } from "../tools/ios";
import { SoundEffect, SupportedLanguagesEnum } from "../interfaces";

// TODO: Split this into 2 components (one for story, other for video article).
// TODO: Add a prop to get the analytics item list name.

class VideoCard extends Component {
  state = {
    showSheet: false,
  };

  handleShare = async () => {
    const {
      _id,
      slug,
      title,
      medical_specialties,
      journal,
      company,
      publisher,
      congress,
    } = this.props.video;
    const url =
      window.location.origin +
      `/video/${slug}?shared=${this.props.user?.user?.uid}`;

    const publisherName =
      publisher === "journal"
        ? journal.name
        : publisher === "congress"
          ? congress.name
          : company.name;

    const shareText =
      i18n.resolvedLanguage === SupportedLanguagesEnum.FR
        ? `Je viens de regarder cette vidéo qui pourrait t'intéresser : ${title} par ${publisherName}`
        : `I’ve just watched this video you may be interested in: ${title} by ${publisherName}`;

    const { isRedirected } = await preflightUser({
      history: this.props.history,
      onboardingMessage: t("error:notOnboarded.shareContent"),
    });
    if (isRedirected) return;

    if (isAndroid) {
      androidShare(url, shareText);
    } else if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: shareText,
          url,
        })
        .then(async () => {
          await this.props.patchUserContent({ slug, action: "share" });
          displayToast(t("you have shared a video"));
          iosPlaySound(SoundEffect.SHARE);

          gaEventVideoShare({
            video: this.props.video,
            listName: "", // TODO: Missing list name.
          });
        });
    } else {
      displayToast(t("toast.fail.browser"));
    }
  };

  render() {
    const { showSheet } = this.state;

    const youtubeId = this.props.video?.sourceURL?.split("v=")[1];

    return (
      <div>
        {this.props.variant === "story" ? (
          <StoryWrapper onClick={this.props.onClick}>
            <img src={this.props.video.apiVideo.thumbnail} alt='' />
            <p>{this.props.video.title}</p>
          </StoryWrapper>
        ) : (
          <CustomWrapper
            style={this.props.style}
            onClick={(e) =>
              e.target?.id !== "video-card-ellipsis" &&
              this.props.history.push({
                pathname: "/video/" + this.props.video.slug,
                state: {
                  video: this.props.video,
                },
              })
            }
            thumbnail={`https://i3.ytimg.com/vi/${youtubeId?.split("&")?.[0]
              }/hqdefault.jpg`}
          >
            <CustomIcon scale={0.7} iconName='play_circle' />

            <div className='header'>
              <div className='specialties'>
                {!this.props.video.medical_specialties.length && (
                  this.props.video.medical_specialties.map(
                    (medical_specialty) => (
                      <SpecialtyTag
                        style={{ padding: 0 }}
                        key={medical_specialty._id + "specialtytagvideo"}
                      >
                        {getItemTranslation(medical_specialty)}
                      </SpecialtyTag>
                    )
                  )
                )}
              </div>
              <img
                id='video-card-ellipsis'
                src={ShareBtn}
                alt=''
                onClick={(e) => {
                  e.preventDefault();
                  disableScroll.on();
                  this.setState({ showSheet: true });
                }}
              />
            </div>

            <div className='content'>
              <p>{renderDate(this.props.video.publication_date)}</p>
              <h5>{this.props.video.title}</h5>
            </div>
          </CustomWrapper>
        )}

        <BottomSheet
          expandOnContentDrag
          maxHeight={window.innerHeight * 0.3}
          open={showSheet}
          className='remove-scrollbar'
          onDismiss={() => {
            disableScroll.off();
            setTimeout(() => this.setState({ showSheet: false }));
          }}
        >
          <div
            style={{ float: "right", marginRight: "20px" }}
            onClick={() => {
              disableScroll.off();
              this.setState({ showSheet: false });
            }}
          >
            <CustomIcon iconName='close_alt' />
          </div>
          <div
            style={{
              padding: "55px 15px",
            }}
          >
            <Row
              justify='around'
              align='center'
              gutterWidth={20}
              onClick={this.handleShare.bind(this)}
            >
              <Col xs={1}>
                <Spacer height='5px' />

                <CustomIcon
                  iconName='share-alt'
                  scale={0.8}
                  color='#212121'
                  style={{
                    marginLeft: 5,
                  }}
                />
              </Col>
              <Col xs={10}>
                <p
                  style={{
                    fontSize: "17px",
                    fontWeight: 600,
                    margin: 0,
                  }}
                >
                  {t("Share")}
                </p>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: 400,
                    margin: 0,
                  }}
                >
                  {t("playlist.sheet.share.video.subline")}
                </p>
              </Col>
            </Row>
            <Spacer height='30px' />
          </div>
        </BottomSheet>
      </div>
    );
  }
}

function mapState(state) {
  const user = state.user;
  return {
    user,
  };
}

const mapDispatch = {
  getUser: userApi.endpoints.getUser.initiate,
  getPlaylists: userApi.endpoints.getPlaylists.initiate,
  patchUserContent: userApi.endpoints.patchUserContent.initiate,
};
const connector = connect(mapState, mapDispatch);

export default connector(withRouter(VideoCard));

const StoryWrapper = styled.div`
  box-sizing: border-box;

  img {
    width: 200px;
    height: 166px;

    object-fit: cover;
    border: 2px solid #ffc408;
    border-radius: 10px;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.8) 0%,
        rgba(240, 240, 240, 0.9) 100%
      ),
      url(${JuisciBacground}) !important;
    background-size: contain;
    background-color: #e5e5e5;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: left;
    color: #212121;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CustomWrapper = styled.div`
  position: relative;
  background-image: linear-gradient(
      rgba(33, 33, 33, 0.8) 0%,
      rgba(76, 88, 97, 0.9) 40%,
      rgba(76, 88, 97, 1) 100%
    ),
    url(${(props) => props.thumbnail});

  background-size: cover;
  background-position: center;
  height: 200px;
  border-radius: 15px;
  box-sizing: border-box;
  margin: 16px 12px;
  padding: 12px 16px;

  .--custom-icon {
    position: absolute;
    left: 40%;
    top: calc(40% - 20px);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 8px;

    .specialties {
      display: flex;
      align-items: start;
      flex-wrap: wrap;
      gap: 8px;
      align-self: start;
      max-height: 48px;
      overflow: hidden;
    }
  }

  .content {
    position: absolute;
    bottom: 16px;

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 120%;
      letter-spacing: 0.04em;
      color: #ffffff;
      margin: 0 0 4px;
      width: 100%;
    }

    h5 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.02em;
      color: #ffffff;
      margin: 0;
    }
  }
`;
