import { Component } from "react";
import styled from "styled-components";
import { renderDate } from "../../domains/app/utils/renderDate";
import { t } from "i18next";
import i18n from "../../config/i18n";
import { Link } from "react-router-dom";
import Swipeout from "rc-swipeout";
import CustomIcon from "../CustomIcon";
import { gaEventSelectItemVideo } from "../../tools/analytics/videoAnalytics";
import { gaEventSelectItemNectar } from "../../tools/analytics/nectarAnalytics";
import { gaEventSelectItemArticle } from "../../tools/analytics/articleAnalytics";
import { SupportedLanguagesEnum } from "../../interfaces";
import { JuisciGPTBadge } from "../nectar/JuisciGPTBadge";
import { VideoFormat } from "../../domains/video/video.types";
import { ContentFormatsEnum } from "../../interfaces/content.interface";
import { SearchFilterPublicationTypeEnum } from "../../domains/search/search.types";
import SpecialtyBadges from "../app/SpecialtyBadges";

class PreviewCard extends Component {
  state = { isDeleted: false };

  getContentType() {
    const { content } = this.props;
    if ([content?.content_format, content?.contentFormat].includes("Video"))
      return ContentFormatsEnum.VIDEO;
    if ([content?.content_format, content?.contentFormat].includes("Nectar"))
      return ContentFormatsEnum.NECTAR;
    else return ContentFormatsEnum.ARTICLE;
  }

  handleSelectItem = () => {
    if (this.getContentType() === ContentFormatsEnum.VIDEO) {
      gaEventSelectItemVideo({
        video: this.props.content,
        listName: this.props.analyticsListName,
      });
    } else if (this.getContentType() === ContentFormatsEnum.NECTAR) {
      gaEventSelectItemNectar({
        nectar: this.props.content,
        listName: this.props.analyticsListName,
      });
    } else {
      gaEventSelectItemArticle({
        article: this.props.content,
        listName: this.props.analyticsListName,
      });
    }
  };

  render() {
    const { content, disabled, onRemove, onClick } = this.props;
    const contentType = this.getContentType();

    const youtubeId =
      contentType === ContentFormatsEnum.VIDEO &&
        content.videoFormat === VideoFormat.ARTICLE
        ? content.sourceURL?.split("v=")[1]
        : undefined;

    return (
      <StyledCard
        className={contentType.toLowerCase()}
        style={{
          ...this.props.style,
          display: this.state.isDeleted ? "none" : "block",
        }}
        onClick={() => {
          onClick && onClick();
          this.props.analyticsListName && this.handleSelectItem();
        }}
      >
        {/* BUG: Doesn't seem to have any effect. */}
        <Swipeout
          style={{ borderRadius: "10px" }}
          disabled={!onRemove || disabled}
          right={[
            {
              text: (
                <CustomIcon
                  className="trash-icon"
                  iconName="trash"
                  color="white"
                />
              ),
              onPress: () => {
                onRemove();
                this.setState({ isDeleted: true });
              },
              style: {
                position: "relative",
                backgroundColor: "red",
                color: "white",
                borderRadius: "0px 15px 15px 0px",
                transform: "translateY(2px)",
                height: "calc(100% - 2px)",
                width: 56,
              },
            },
          ]}
        >
          <div className="card-content">
            <Link
              className={`react-link ${content.videoFormat === VideoFormat.STORY ? "flex" : ""
                }`}
              to={{
                pathname:
                  ({
                    [ContentFormatsEnum.ARTICLE]: "/post/",
                    [ContentFormatsEnum.VIDEO]: `/video/${content.videoFormat === VideoFormat.STORY ? "story/" : ""
                      }`,
                    [ContentFormatsEnum.NECTAR]: "/nectar/",
                  }[contentType] ?? "") + content.slug,
                state: { ...this.props.locationState },
              }}
            >
              <div
                className={
                  contentType == ContentFormatsEnum.VIDEO ? "content-infos" : ""
                }
              >
                <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                  {<SpecialtyBadges specialties={(content.medical_specialties || content.medicalSpecialties).slice(0, 3)} />}
                </div>
                <div className={`content-type ${contentType.toLowerCase()}`}>
                  {{
                    [ContentFormatsEnum.ARTICLE]: t(
                      `articleTypes.${content?.article_type ??
                      SearchFilterPublicationTypeEnum.CLINICAL_STUDY
                      }`
                    ),
                    [ContentFormatsEnum.VIDEO]: t("contentType.video"),
                    [ContentFormatsEnum.NECTAR]: t("contentType.question"),
                  }[contentType] ?? ""}
                </div>
                <h5>{content?.question ?? content.title}</h5>
                <div className="footer">
                  <div className="footer-el">
                    {(content?.publication_date ??
                      content?.publicationDate) && (
                        <>
                          {renderDate(
                            content?.publication_date ?? content?.publicationDate
                          )}
                        </>
                      )}
                    <div className="bold">
                      {
                        {
                          [ContentFormatsEnum.ARTICLE]:
                            content.journal?.name ?? content?.journal ?? null,
                          [ContentFormatsEnum.VIDEO]:
                            content[content?.publisher ?? "company"]?.name,
                          // TODO: Mettre ici le nom du publisher d'un nectar
                          [ContentFormatsEnum.NECTAR]: "Juisci Originals",
                        }[contentType]
                      }
                    </div>
                  </div>

                  {contentType === ContentFormatsEnum.ARTICLE && (
                    <div className="footer-el">
                      {t("Authors")}
                      <div className="bold">
                        {content?.primary_author}{" "}
                        {content?.authors?.length
                          ? i18n.resolvedLanguage === SupportedLanguagesEnum.FR
                            ? `et ${content.authors.length} autres auteurs`
                            : `and ${content.authors.length} other authors`
                          : ""}
                      </div>
                    </div>
                  )}
                  {contentType === ContentFormatsEnum.NECTAR && (
                    <div className="footer-el">
                      <JuisciGPTBadge className="small" />
                    </div>
                  )}
                </div>
              </div>

              {contentType === ContentFormatsEnum.VIDEO && (
                <div
                  className="video-thumbnail"
                  style={{
                    backgroundSize: "cover",
                    backgroundImage: `url("${content?.videoFormat === VideoFormat.STORY
                      ? content?.apiVideo?.thumbnail
                      : `https://img.youtube.com/vi/${youtubeId?.split("&")[0]
                      }/hqdefault.jpg`
                      }")`,
                  }}
                />
              )}
            </Link>
          </div>
        </Swipeout>
      </StyledCard>
    );
  }
}

export default PreviewCard;

const StyledCard = styled.div`
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
  text-align: left;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 59.9%,
      #fff3ce 100%
    ),
    #fff;

  &.video {
    background: var(
      --GRADIENTS-SPECIALS-Videos-Card,
      linear-gradient(
        180deg,
        rgba(249, 249, 249, 0) 33.85%,
        rgba(206, 8, 104, 0.2) 100%
      ),
      #fff
    );
  }

  &.nectar {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 59.9%,
        rgba(238, 248, 34, 0.25) 100%
      ),
      #fff;
  }

  .trash-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
  }

  .card-content {
    padding: 24px 16px;

    .react-link {
      display: block;
      color: #212121;
      text-decoration: none;

      &.flex {
        display: flex;
      }

      &:focus,
      &:hover,
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
      }
    }

    .content-type {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #f1353a;
      margin: 12px 0;

      &.nectar {
        color: #2aa85e;
      }
    }

    h5 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -6e-5em;
      margin-top: 8px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: start;
      gap: 8px;

      .footer-el {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        min-width: 100px;

        .bold {
          display: block;
          font-weight: 700;
        }
      }
    }

    .video-thumbnail {
      width: 80px;
      height: auto;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .content-infos {
      width: calc(100% - 80px);
      padding-right: 16px;
    }
  }

  &.video .card-content {
    padding: 17px 12px;
  }
`;
