import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SupportedLanguagesEnum } from "../../interfaces";
import { useRoomContext } from "../../domains/app/contexts/room.context";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { UserRoomModel } from "../../domains/user/user.types";
import { loadMarkdown } from "../../domains/app/utils/loadMarkdown";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { useRoomPreflightTemp } from "../../domains/room/hooks/useRoomPreflightTemp";
import disableScroll from "disable-scroll";
import Loader from "../../components/Loader";
import { PolicyTextStyle } from "../../components/PolicyTextStyle";
import ContentMarkdown from "../../components/ContentMarkdown";
import styled from "styled-components";
import { RoomHeader, RoomPageWrapper } from "../../components/room/layout";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  NavBack,
  NavLang,
} from "../../components/app/headers/layout";
import { Tab, Tabs } from "@mui/material";
import { displayToast } from "../../components/app/AppToast";
import defaultPrivacyEnMd from "../../assets/markdowns/juisci-privacy-en.md";
import defaultTermsEnMd from "../../assets/markdowns/juisci-terms-en.md";
import defaultPrivacyEsMd from "../../assets/markdowns/juisci-privacy-es.md";
import defaultTermsEsMd from "../../assets/markdowns/juisci-terms-es.md";
import biocodexPrivacyMd from "../../assets/markdowns/biocodex-privacy.md";
import biocodexTermsMd from "../../assets/markdowns/biocodex-terms.md";
import takedaPrivacyEnMd from "../../assets/markdowns/takeda-privacy-en.md";
import takedaPrivacyFrMd from "../../assets/markdowns/takeda-privacy-fr.md";
import takedaTermsEnMd from "../../assets/markdowns/takeda-terms-en.md";
import takedaTermsFrMd from "../../assets/markdowns/takeda-terms-fr.md";

export default function RoomTermsPrivacyPage() {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const { currentRoomId } = useRoomContext();
  const { activeLang, t } = useLangContext();
  const [termsContent, setTermsContent] = useState<string | null>(null);
  const [privacyContent, setPrivacyContent] = useState<string | null>(null);
  const { roomData, preflightUserRoom } = useRoomPreflightTemp(roomId);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    (async function () {
      try {
        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.default"),
        });
        if (isRedirected) return;

        disableScroll.off();

        // NOTE: Temporary check until we get a true access guard on rooms.
        await preflightUserRoom();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    if (currentRoomId) {
      scrollTo(0, 0);
      loadTexts();
    }
  }, [currentRoomId, activeLang]);

  async function loadTexts() {
    try {
      let terms;
      let privacy;

      // NOTE: This hardcoded implementation is temporary.
      if (roomData?.organisation.uid?.toLowerCase().includes("biocodex")) {
        terms = await loadMarkdown(biocodexTermsMd);
        privacy = await loadMarkdown(biocodexPrivacyMd);
        // NOTE: This hardcoded implementation is temporary.
      } else if (roomData?.organisation.uid?.toLowerCase().includes("takeda")) {
        if (activeLang === SupportedLanguagesEnum.FR) {
          terms = await loadMarkdown(takedaTermsFrMd);
          privacy = await loadMarkdown(takedaPrivacyFrMd);
        } else {
          terms = await loadMarkdown(takedaTermsEnMd);
          privacy = await loadMarkdown(takedaPrivacyEnMd);
        }
      } else {
        if (activeLang === SupportedLanguagesEnum.ES) {
          terms = await loadMarkdown(defaultTermsEsMd);
          privacy = await loadMarkdown(defaultPrivacyEsMd);
        } else {
          terms = await loadMarkdown(defaultTermsEnMd);
          privacy = await loadMarkdown(defaultPrivacyEnMd);
        }
      }

      setTermsContent(terms as string);
      setPrivacyContent(privacy as string);
    } catch (error) {
      console.error("Couldn't load files.", error);
      displayToast(t("error:default"));
    }
  }

  return (
    <RoomPageWrapper style={{ backgroundColor: "white" }}>
      <HeaderBoxAutoSafe>
        <HeaderLinks>
          <NavBack />
        </HeaderLinks>
        <RoomHeader room={roomData as UserRoomModel} />
        <HeaderLinks>
          <NavLang />
        </HeaderLinks>
      </HeaderBoxAutoSafe>

      {!termsContent || !privacyContent ? (
        <Loader />
      ) : (
        <>
          <ContentTabs
            variant="fullWidth"
            centered
            value={activeTab}
            onChange={(_, index: number) => {
              setActiveTab(index);
            }}
          >
            <Tab label={t("common:termsOfUse")} />
            <Tab label={t("common:privacyPolicy")} />
          </ContentTabs>
          <Container>
            {activeTab === 0 && (
              <PolicyTextStyle>
                <ContentMarkdown>{termsContent}</ContentMarkdown>
              </PolicyTextStyle>
            )}

            {activeTab === 1 && (
              <PolicyTextStyle>
                <ContentMarkdown>{privacyContent}</ContentMarkdown>
              </PolicyTextStyle>
            )}
          </Container>
        </>
      )}
    </RoomPageWrapper>
  );
}

const Container = styled.div`
  padding: 0px 30px calc(30px + var(--safe-area-bottom));
`;

const ContentTabs = styled(Tabs)`
  background: #ecf0f5;

  .MuiButtonBase-root {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 5e-5em;
    text-transform: capitalize; // NOTE: If this is removed, the text will be capitalized ???
    color: #90a4ae;
    height: 60px;

    &.Mui-selected {
      color: #212121;
    }
  }

  .MuiTabs-indicator {
    height: 1.5px;
    background-color: #212121;
  }
`;
