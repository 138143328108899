import { CSSProperties, MouseEvent, useState } from "react";
import { NectarModel } from "../../domains/nectar/nectar.types";
import { useLikeNectar } from "../../domains/user/hooks/useLikeNectar";
import { useSaveNectar } from "../../domains/user/hooks/useSaveNectar";
import { useShareNectar } from "../../domains/user/hooks/useShareNectar";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import styled from "styled-components";
import { t } from "i18next";
import OptionsPanel from "../app/OptionsPanel";
import PlaylistPanel from "../app/PlaylistPanel";
import { StoryCoverBody, StoryCoverContainer, StoryCoverFooter, StoryCoverHeader, StoryCoverTitle, StoryCoverType } from "./layout";
import { CardActions, CardDate, CardTags } from "../cards/layout";
import { ReactComponent as JuisciOriginalLogo } from "../../assets/core/juisci-original.svg";
import { JuisciGPTBadge } from "../nectar/JuisciGPTBadge";

export default function StoryCoverNectar({
  nectar,
  style,
  onClick,
  onOptionsOpen,
  onOptionsClose,
}: {
  nectar: NectarModel;
  style?: CSSProperties;
  onClick?: Function;
  onOptionsOpen?: Function;
  onOptionsClose?: Function;
}) {
  const [isOptionsPanelVisible, setOptionsPanelVisible] = useState(false);
  const [isPlaylistsPanelVisible, setPlaylistsPanelVisible] = useState(false);
  const { isLiked, toggleLike } = useLikeNectar(nectar, AnalyticsItemList.FORYOU);
  const { isSaved, toggleSaveToPlaylists } = useSaveNectar(nectar, AnalyticsItemList.FORYOU);
  const shareNectar = useShareNectar(nectar, AnalyticsItemList.FORYOU);

  return (
    <>
      <Container
        className="story-cover-container"
        style={style}
        onClick={() => onClick && onClick()}
      >
        <StoryCoverHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "8px",
            }}
          >
            <CardDate date={nectar.publication_date} />
            <CardTags
              contentLabel="nectar"
              tags={nectar.medical_specialties}
            />
          </div>
          <CardActions
            isLiked={isLiked}
            onLike={(e: MouseEvent) => {
              e.stopPropagation();
              toggleLike();
            }}
            onOptions={(e: MouseEvent) => {
              e.stopPropagation();
              setOptionsPanelVisible(true);
              onOptionsOpen && onOptionsOpen();
            }}
          />
        </StoryCoverHeader>

        <StoryCoverBody>
          <StoryCoverType>{t("Nectar")}</StoryCoverType>
          <StoryCoverTitle lines={8}>{nectar.title}</StoryCoverTitle>
        </StoryCoverBody>

        <StoryCoverFooter style={{ flexDirection: "column" }}>
          <JuisciOriginalLogo />
          <JuisciGPTBadge />
        </StoryCoverFooter>
      </Container>

      <OptionsPanel
        isOpen={isOptionsPanelVisible}
        onDismiss={() => {
          setOptionsPanelVisible(false);
          onOptionsClose && onOptionsClose();
        }}
        isContentSaved={isSaved}
        isContentLiked={isLiked}
        onSaveContent={() => setPlaylistsPanelVisible(true)}
        onLikeContent={toggleLike}
        onShareContent={shareNectar}
      />

      <PlaylistPanel
        isVisible={isPlaylistsPanelVisible}
        contentToAdd={nectar}
        onClose={() => setPlaylistsPanelVisible(false)}
        onSave={async ({
          add,
          remove,
        }: {
          add: string[];
          remove: string[];
        }) => {
          await toggleSaveToPlaylists({ add, remove });
        }}
      />
    </>
  );
}

const Container = styled(StoryCoverContainer)`
  background: linear-gradient(180deg, #EEF822 0%, rgba(255, 255, 255, 0.00) 100%), #F7F6F2;
`;
