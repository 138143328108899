import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../redux";
import { SearchCategory } from "../../domains/search/search.types";
import CustomIcon from "../CustomIcon";
import { t } from "i18next";
import { setContextScrollerPosition } from "../../domains/search/search.reducer";

export default function SearchCategoryTabs({ onTabClick }: { onTabClick?: Function }) {
	const { ALL, ARTICLES, JOURNALS, PLAYLISTS, USERS, VIDEOS, NECTARS } = SearchCategory;
	const dispatch = useAppDispatch();
	const selectedContextTab = useAppSelector((state) => state.search.selectedContextTab);
	const contextScrollerPosition = useAppSelector((state) => state.search.contextScrollerPosition);
	const cache = useAppSelector((state) => state.search.cache);
	const scrollerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		scrollerRef.current?.scrollTo({ left: contextScrollerPosition });
	}, []);

	function handleScroll() {
		if (scrollerRef.current) {
			dispatch(setContextScrollerPosition(scrollerRef.current.scrollLeft));
		}
	}

	return (
		<div ref={scrollerRef} className={`category-scroll scrollbar-hidden`} onScroll={handleScroll}>
			<ContextTab
				label={t("search:allCategories")}
				iconName="search" iconWidth={20} iconHeight={20}
				visible
				selected={selectedContextTab === ALL}
				onClick={() => onTabClick && onTabClick(ARTICLES)}
			/>

			<ContextTab
				label={t("Publications") + ` (${cache.articles.total})`}
				iconName="paperscroll" iconWidth={20} iconHeight={20}
				visible={cache.articles.total > 0}
				selected={selectedContextTab === ARTICLES}
				onClick={() => onTabClick && onTabClick(ARTICLES)}
			/>

			<ContextTab
				label={t("Journals") + ` (${cache.journals.total})`}
				iconName="documents" iconWidth={22} iconHeight={22}
				visible={cache.journals.total > 0}
				selected={selectedContextTab === JOURNALS}
				onClick={() => onTabClick && onTabClick(JOURNALS)}
			/>

			<ContextTab
				label={t("Playlists") + ` (${cache.playlists.total})`}
				iconName="playlist" iconWidth={16} iconHeight={16}
				visible={cache.playlists.total > 0}
				selected={selectedContextTab === PLAYLISTS}
				onClick={() => onTabClick && onTabClick(PLAYLISTS)}
			/>

			<ContextTab
				label={t("Users") + ` (${cache.users.total})`}
				iconName="user" iconWidth={18} iconHeight={18}
				visible={cache.users.total > 0}
				selected={selectedContextTab === USERS}
				onClick={() => onTabClick && onTabClick(USERS)}
			/>

			<ContextTab
				label={t("Videos") + ` (${cache.videos.total})`}
				iconName="video-player" iconWidth={21} iconHeight={20}
				visible={cache.videos.total > 0}
				selected={selectedContextTab === VIDEOS}
				onClick={() => onTabClick && onTabClick(VIDEOS)}
			/>

			<ContextTab
				label={t("Nectars of Science") + ` (${cache.nectars.total})`}
				iconName="nectar-drop-search" iconWidth={21} iconHeight={20}
				visible={cache.nectars.total > 0}
				selected={selectedContextTab === NECTARS}
				onClick={() => onTabClick && onTabClick(NECTARS)}
			/>
		</div>
	);
}

function ContextTab({
	label = "",
	iconName = "",
	iconWidth = 16,
	iconHeight = 16,
	visible = false,
	selected = false,
	onClick = () => { },
}) {
	if (!visible) return null;
	return (
		<div
			className={`tab ${selected ? "selected" : ""}`}
			onClick={onClick}
		>
			<div className='tab-inner'>
				<CustomIcon
					iconName={iconName}
					width={iconWidth}
					height={iconHeight}
					color='#212121'
				/>
				<span className='category-title'>{label}</span>
			</div>
		</div>
	);
}