import { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { setUser } from "../../redux/user/user.reducer";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { getPlaylistById } from "../../domains/playlist/endpoints/getPlaylistById";
import { savePlaylist } from "../../domains/playlist/endpoints/savePlaylist";
import { unsavePlaylist } from "../../domains/playlist/endpoints/unsavePlaylist";
import { sharePlaylist } from "../../domains/playlist/endpoints/sharePlaylist";
import disableScroll from "disable-scroll";
import Loader from "../../components/Loader";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTagline, HeaderTitle, NavBack, NavOptions } from "../../components/app/headers/layout";
import ItemList from "../../components/app/ItemList";
import CustomIcon from "../../components/CustomIcon";
import PreviewCard from "../../components/profile/PreviewCard";
import { BottomSheet } from "react-spring-bottom-sheet";
import { SheetWrapper } from "../../components/CustomSheet";
import ShareSheet from "../../components/ShareSheet";
import {
  SoundEffect,
  SupportedLanguagesEnum,
} from "../../interfaces";
import styled from "styled-components";
import { iosPlaySound, iosSetBackgroundColor, isNativeIOS } from "../../tools/ios";
import { pickUserImage } from "../../domains/image/utils/pickUserImage";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";
import i18n from "../../config/i18n";
import BottomPanel from "../../components/app/panels/BottomPanel";
import { PanelContainer, PanelRow } from "../../components/app/panels/layout";

export default function PublicPlaylistPage() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const { currentUser } = useCurrentUser();
  const [playlist, setPlaylist] = useState<any | null>(null); // TODO: Type playlist.
  const [hasScrolled, setScrolled] = useState(false);
  const [isSheetVisible, setSheetVisible] = useState(false);
  // TODO: Move to hook.
  const isSaved = currentUser && currentUser?.playlists.find(
    (el: { _id: string }) => el._id === playlist?._id
  );

  useEffect(() => {
    (async function () {
      try {
        window.scrollTo(0, 0);
        disableScroll.off();
        if (isNativeIOS) iosSetBackgroundColor("#FFFFFF");

        handlePageScroll();
        window.addEventListener("scroll", handlePageScroll);

        setPlaylist(await getPlaylistById(params.id));
      } catch (error) {
        console.error("Couldn't mount public playlist page.", error);
        displayToast(t("error:default"));
        history.replace("/discovery");
      }
    })();

    return () => window.removeEventListener("scroll", handlePageScroll);
  }, []);

  useEffect(() => {
    if (isSheetVisible) {
      disableScroll.on();
    } else {
      disableScroll.off();
    }
  }, [isSheetVisible]);

  function handlePageScroll() {
    setScrolled(window.scrollY > 100);
  }

  // TODO: Move to hook.
  async function handleSavePlaylist() {
    try {
      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.savePlaylist"),
      });
      if (isRedirected) return;

      if (!isSaved) {
        iosPlaySound(SoundEffect.SAVE);
        displayToast(t("Playlist saved successfuly"));
        await savePlaylist(playlist._id);
        dispatch(setUser({
          ...currentUser,
          playlists: [
            ...currentUser.playlists,
            playlist,
          ],
        }));
      } else {
        displayToast(t("Playlist unsaved successfuly"));
        await unsavePlaylist(playlist._id);
        dispatch(setUser({
          ...currentUser,
          playlists: [
            ...currentUser.playlists.filter(
              (el: { _id: string }) => el._id !== playlist._id
            ),
          ],
        }));
      }
    } catch (error) {
      console.error("Couldn't save/unsave playlist.", error);
      displayToast(t("error:default"));
    }
  }

  // TODO: Move to hook.
  async function handleSharePlaylist() {
    try {
      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.sharePlaylist"),
      });
      if (isRedirected) return;

      const userUid = user?.user?.uid;

      if (navigator.share) {
        navigator
          .share({
            title: document.title,
            text:
              // TODO: Localize.
              i18n.resolvedLanguage === SupportedLanguagesEnum.FR
                ? `Découvrez la playlist ${playlist.title} sur Juisci !`
                : `Discover the playlist ${playlist.title} on Juisci now!`,
            url: `/playlist/public/${playlist._id}?shared=${userUid ?? "null"}`,
          })
          .then(() => {
            displayToast(t("playlist shared"), "success");
            iosPlaySound(SoundEffect.SHARE);
            sharePlaylist(playlist._id);
          });
      } else {
        displayToast(t("toast.fail.browser"));
      }
    } catch (error) {
      console.error("Couldn't share playlist.", error);
      displayToast(t("error:default"));
    }
  }

  if (!playlist) return <Loader />;

  return (
    <>
      <Wrapper>
        <HeaderBoxAutoSafe transparent={!hasScrolled}>
          <HeaderLinks>
            <NavBack onClick={() =>
              !!location.key
                ? history.goBack()
                : history.replace(`/discovery`)
            } />
          </HeaderLinks>
          <HeaderTitle>
            <HeaderTagline>{t("Playlist by")}</HeaderTagline>
            {hasScrolled && (
              <>{playlist?.user?.fullname ?? playlist?.company?.name}</>
            )}
          </HeaderTitle>
          <HeaderLinks>
            <NavOptions onClick={() => setSheetVisible(true)} />
          </HeaderLinks>
        </HeaderBoxAutoSafe>

        <PageHeader>
          {!!playlist.user ? (
            <Link
              to={{
                pathname: "/profile/user/" + playlist?.user?.uid,
                state: {
                  user: playlist?.user,
                },
              }}
            >
              <img
                style={{
                  borderRadius: 100,
                  objectFit: "cover",
                  width: 72,
                  height: 72,
                }}
                src={pickUserImage(playlist?.user)}
                alt=''
              />
              <div className='company_link'>
                {t("common:by") + " " + playlist.user.fullname}
              </div>
              <h5>{playlist.title}</h5>
            </Link>
          ) : !!playlist.company ? (
            <Link
              to={{
                pathname: "/company/" + playlist?.company?._id,
                state: {
                  company: playlist?.company,
                },
              }}
            >
              <img src={playlist.company?.images[0]?.url} alt='' />
              <div className='company_link'>
                {t("Discover") + " " + playlist.company.name}
              </div>
              <h5>{playlist.title}</h5>
            </Link>
          ) : (
            <h5>{playlist?.title}</h5>
          )}
        </PageHeader>
        <ItemList>
          {[
            ...playlist?.playlist,
            ...playlist?.playlistContent,
          ]
            .sort((
              a: { publication_date?: Date; publicationDate?: Date },
              b: { publication_date?: Date; publicationDate?: Date },
            ) => {
              const dateA = a.publication_date ?? a.publicationDate;
              const dateB = b.publication_date ?? b.publicationDate;
              if (dateA && dateB) {
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
              }
              return 0;
            })
            ?.map((content, index) => (
              <PreviewCard
                key={content._id + "--profile-card-key" + index}
                content={{ ...content }}
                itemListName={AnalyticsItemList.PLAYLIST_PAGE}
              />
            ))}
        </ItemList>
      </Wrapper>

      <BottomPanel
        isOpen={isSheetVisible}
        onClose={() => setTimeout(() => setSheetVisible(false), 100)}
      >
        <SheetWrapper style={{ height: window.innerHeight * 0.3 }}>
          <PanelContainer>
            <PanelRow
              style={{ color: isSaved ? "#FF699C" : "#212121" }}
              onClick={handleSavePlaylist}
            >
              <CustomIcon
                iconName={isSaved ? "save.fill" : "save"}
                color='#212121'
              />
              <div className="text">
                <span className="label">{isSaved
                  ? t("Unsave playlist")
                  : t("Save playlist")
                }</span>
                <span className="description">{isSaved
                  ? t("Remove this playlist from your saved folder")
                  : t("Add this playlist to your saved folder")
                }</span>
              </div>
            </PanelRow>
            <PanelRow onClick={handleSharePlaylist}>
              <CustomIcon iconName='share-alt' color='#212121' />
              <div className="text">
                <span className="label">{t("Share this playlist")}</span>
                <span className="description">{t("playlist.sheet.share.subline")}</span>
              </div>
            </PanelRow>
          </PanelContainer>
        </SheetWrapper>
      </BottomPanel>

      {/* TODO: Remove once new component has been tested. */}
      <BottomSheet
        expandOnContentDrag
        // open={isSheetVisible}
        open={false}
        onDismiss={() =>
          setTimeout(() => setSheetVisible(false), 100)
        }
      >
        <SheetWrapper style={{ height: window.innerHeight * 0.3 }}>
          <PanelContainer>
            <PanelRow
              style={{ color: isSaved ? "#FF699C" : "#212121" }}
              onClick={handleSavePlaylist}
            >
              <CustomIcon
                iconName={isSaved ? "save.fill" : "save"}
                color='#212121'
              />
              <div className="text">
                <span className="label">{isSaved
                  ? t("Unsave playlist")
                  : t("Save playlist")
                }</span>
                <span className="description">{isSaved
                  ? t("Remove this playlist from your saved folder")
                  : t("Add this playlist to your saved folder")
                }</span>
              </div>
            </PanelRow>
            <PanelRow onClick={handleSharePlaylist}>
              <CustomIcon iconName='share-alt' color='#212121' />
              <div className="text">
                <span className="label">{t("Share this playlist")}</span>
                <span className="description">{t("playlist.sheet.share.subline")}</span>
              </div>
            </PanelRow>
          </PanelContainer>
        </SheetWrapper>
      </BottomSheet>

      <ShareSheet type='playlist' />
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  background: #f7f8fc;
`;

const PageHeader = styled.div`
  margin-bottom: 16px;
  padding: 70px 21px 40px;
  padding-top: calc(70px + var(--safe-area-top));;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(
    144.76deg,
    #ffffff 0%,
    #ecf0f5 70.37%,
    #fcc408 139.87%
  );

  .company_link {
    color: #f1353a;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.04em;
    background: #fff;
    border-radius: 5rem;
    padding: 8px 16px;
    box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
    display: table;
    margin: 0px auto 20px;
  }

  img {
    display: block;
    margin: 0px auto 10px;
    /* padding: 10px 16px; */
    width: 120px;
    height: 84px;
    border-radius: 10px;
    /* background: #fff; */
    /* box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1); */
    object-fit: contain;
  }

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #212121;
    margin: 0 auto;
  }
`;
