import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../redux";
import { UserConnectionRequestModel, UserModel } from "../../domains/user/user.types";
import { ConversationModel } from "../../domains/messaging/messaging.types";
import { getUserConversations } from "../../domains/messaging/endpoints/getUserConversations";
import { useLazyGetPendingConnectionRequestsQuery } from "../../domains/user/endpoints/getPendingConnectionRequests";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { getNotifications } from "../../domains/notification/endpoints/getNotifications";
import CustomIcon from "../../components/CustomIcon";
import MessageHeader from "../../components/app/headers/MessageHeader";
import Loader from "../../components/Loader";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";
import { Tabs } from "antd-mobile";
import ConversationRow from "../../components/messaging/ConversationRow";
import ConnectionRequestRow from "../../components/messaging/ConnectionRequestRow";
import Fuse from "fuse.js";
import { ReactComponent as PencilLineIcon } from "../../assets/icons/pencil-line.svg";
import { SocketContext } from "../../domains/app/contexts/socket.context";

export default function MessageListPage() {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const pendingRequestsToSelf = useAppSelector((state) => {
    return state.user.connections.pending.filter((request: UserConnectionRequestModel) => {
      return request.fromUser.uid !== currentUser.uid;
    });
  });
  const [isLoading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [getPendingConnectionRequests] = useLazyGetPendingConnectionRequestsQuery();
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    (async function () {
      try {
        const { isRedirected } = await preflightUser({ history, authOnly: true });
        if (isRedirected) return;

        await loadData();
        setLoading(false);
      } catch (error) {
        console.error("Couldn't mount message list page.", error);
        displayToast(t("error:default"));
        history.replace("/profile");
      }
    })();
  }, []);

  useEffect(() => {
    if (socket) socket.on("conversation/new", loadData);
    if (socket) socket.on("message/new", loadData);
    return () => {
      socket?.off("conversation/new");
      socket?.off("message/new");
    }
  }, [socket]);

  useEffect(() => {
    if (!searchValue.length) {
      setSearchResults([]);
      return;
    }

    // Rewrite conversations to include a dedicated other user field
    const extendedConversations = conversations.map((conversation: ConversationModel) => {
      const extended: any = conversation;
      extended.otherUser = conversation.participants.find((user: UserModel) => {
        return user.uid !== currentUser.uid;
      });
      return extended;
    });

    const fuse = new Fuse(extendedConversations, {
      keys: [
        "otherUser.fullname",
        "otherUser.firstname",
        "otherUser.lastname",
        "otherUser.username",
        "otherUser.profession",
      ],
      includeScore: true,
      findAllMatches: true,
      threshold: 0.3,
    });

    const results: any = fuse.search(searchValue).map((result) => result.item);
    setSearchResults(results);
  }, [searchValue]);

  async function loadData() {
    try {
      const [conversations, notifications] = await Promise.all([
        getUserConversations(),
        getNotifications(),
        getPendingConnectionRequests(), // NOTE: Automatically updates store cache.
      ]);

      // NOTE: May be replaced later with sorting from the backend.
      const sortedConversations = conversations.toSorted((a: ConversationModel, b: ConversationModel) => {
        const aTime = new Date(a.messages.at(-1)?.createdAt as string).getTime() as number;
        const bTime = new Date(b.messages.at(-1)?.createdAt as string).getTime() as number;
        return bTime - aTime ?? 0;
      });

      setConversations(sortedConversations);

      if (!notifications.length) {
        setCurrentTabIndex(0);
        localStorage.setItem("msgTabIndex", "0");
      } else {
        setCurrentTabIndex(parseInt(localStorage.getItem("msgTabIndex") ?? "0"));
      }

      setNotifications(notifications);
    } catch (error) {
      console.error("Couldn't load page data.", error);
    }
  }

  function renderConversations() {
    const items = !searchValue.length ? conversations : searchResults;
    if (!items.length) {
      return (
        <NoResultText color='#212121'>
          {t(!searchValue.length ? "messaging:noUsers" : "messaging:noConversations")}
        </NoResultText>
      );
    }

    return items?.map((conversation: ConversationModel) => {
      return (
        <ConversationRow
          key={conversation?.id}
          conversation={conversation}
          onClick={() => history.push(`/profile/messages/conversation/${conversation?.id}`)}
        // readStatus={conversation?.unreadMessages > 0}
        />
      );
    });
  }

  return (
    <>
      <MessageHeader customActions={
        <PencilLineIcon onClick={() => history.push("/profile/messages/new")} />
      } />
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <TopContainer>
            <SearchBar>
              <CustomIcon iconName='search' color='#90A4AE' />
              <input
                type="search"
                maxLength={50}
                placeholder={t("messaging:searchMessages")}
                value={searchValue}
                onInput={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
              />
            </SearchBar>
          </TopContainer>

          <Tabs
            swipeable={false}
            tabs={[
              { title: <span>{t("messaging:conversations")}</span> },
              { title: <span>{t("messaging:requests")} ({pendingRequestsToSelf?.length ?? 0})</span> },
            ]}
            tabBarUnderlineStyle={{
              borderColor: "#212121",
              borderWidth: "1px",
            }}
            initialPage={currentTabIndex}
            onChange={(_, index) => {
              setCurrentTabIndex(index);
              localStorage.setItem("msgTabIndex", JSON.stringify(index));
            }}
          >
            <div>
              {renderConversations()}
            </div>

            <ConnectionRequestTab requests={pendingRequestsToSelf} />
          </Tabs>
        </Container>
      )}
    </>
  );
}

function ConnectionRequestTab({ requests }: {
  requests: [];
}) {
  const history = useHistory();

  return (
    <div>
      {!requests?.length && <NoResultText>{t("messaging:noRequests")}</NoResultText>}

      {requests?.map((request: UserConnectionRequestModel) => {
        return (
          <ConnectionRequestRow
            key={request?.id + "--request-row"}
            user={request.fromUser}
            onClick={() =>
              history.push(
                `/profile/user/${request?.fromUser?.uid}`
              )
            }
          />
        );
      })}
    </div>
  );
}

const NoResultText = styled.p`
  margin-top: 15px;
  padding: 20px;
  text-align: center;
  font-family: "Inter";
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #212121;
`;

const Container = styled.div`
  background: #F7F8FC;
  min-height: 100dvh;

  .am-tabs-default-bar-content {
    background: var(--SECONDARY-GREY-SHADES-Bluegrey-2, #ECF0F5);
  }
  
  .am-tabs-default-bar-tab {
    height: unset;
    padding: 21px !important;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px; /* 135.714% */
    color: var(--BRAND-CORE-Juisci-Black, #212121);
    border-bottom: 1px solid #D2DCE2 !important;
  }
`;

const TopContainer = styled.div`
  background: var(--SECONDARY-GREY-SHADES-Bluegrey-1, #F9F9F9);
  padding: 16px 10px;
`;

const SearchBar = styled.div`
  padding: 11px 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  background: var(--SECONDARY-GREY-SHADES-Bluegrey-3, #D2DCE2);

  svg path {
    fill: var(--SECONDARY-GREY-SHADES-Bluegrey-5, #617985);
  }

  input {
    flex: 1;
    border: none;
    background: none;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    color: var(--SECONDARY-GREY-SHADES-Bluegrey-5, #617985);
  }
`;