import mainImageSlideEn1 from "../../assets/images/welcome-pics/3.0/en/slide-1.png";
import mainImageSlideEn2 from "../../assets/images/welcome-pics/3.0/en/slide-2.png";
import mainImageSlideEn3 from "../../assets/images/welcome-pics/3.0/en/slide-3.png";
import mainImageSlideFr1 from "../../assets/images/welcome-pics/3.0/fr/slide-1.png";
import mainImageSlideFr2 from "../../assets/images/welcome-pics/3.0/fr/slide-2.png";
import mainImageSlideFr3 from "../../assets/images/welcome-pics/3.0/fr/slide-3.png";
import bgSlideEn2 from "../../assets/images/welcome-pics/3.0/en/slide-2-bg.png";
import bgSlideFr2 from "../../assets/images/welcome-pics/3.0/fr/slide-2-bg.png";
import bgSlideEn3_1 from "../../assets/images/welcome-pics/3.0/en/slide-3-bg-1.png";
import bgSlideEn3_2 from "../../assets/images/welcome-pics/3.0/en/slide-3-bg-2.png";
import bgSlideFr3_1 from "../../assets/images/welcome-pics/3.0/fr/slide-3-bg-1.png";
import bgSlideFr3_2 from "../../assets/images/welcome-pics/3.0/fr/slide-3-bg-2.png";
import { ReactComponent as VideoIcon } from "../../assets/images/welcome-pics/3.0/slide-3-video-icon.svg";
import { ReactComponent as PdfIcon } from "../../assets/images/welcome-pics/3.0/slide-3-pdf-icon.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/welcome-pics/3.0/slide-3-arrow-icon.svg";
import styled from "styled-components";
import { SupportedLanguagesEnum } from "../../interfaces";

const CustomVideo2Pdf = () => {
  const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .pdf-icon,
    .arrow-icon,
    .video-icon {
      opacity: 0;
      animation: fade-in 1s ease-in-out;
      animation-fill-mode: forwards;
    }

    .pdf-icon {
      animation-delay: 0.25s;
    }

    .arrow-icon {
      animation-delay: 0.5s;
    }

    .video-icon {
      animation-delay: 0.75s;
    }
  `;

  return (
    <StyledContainer>
      <PdfIcon className="pdf-icon" />
      <ArrowIcon className="arrow-icon" />
      <VideoIcon className="video-icon" />
    </StyledContainer>
  );
};

export const WELCOME_SLIDES = {
  [SupportedLanguagesEnum.EN]: [
    {
      title: (
        <span className="c-white">
          {"Discover "}
          <span className="c-yellow">{"JuisciGPT"}</span>
          {" ✨"}
        </span>
      ),
      text: (
        <span className="c-white">
          <span className="c-lightgrey">{"Request and receive "}</span>
          {"evidence based, source-tracked answers "}
          <span className="c-lightgrey">{"to any medical questions."}</span>
        </span>
      ),
      backColor: "linear-gradient(225deg, #212121 0%, #4C5861 100%)",
      image: { src: mainImageSlideEn1, className: "fade-slide-up" },
    },
    {
      title: (
        <span>
          <span className="c-white">{"Nectars"}</span>
          {" of Science 🧃"}
        </span>
      ),
      text: (
        <span className="c-white">
          {"Our AI generates automatically in-depth "}
          {"literature reviews - summaries of summaries - "}
          <span className="c-black">{"crafted from "}</span>
          {"ten of thousands of publications "}
          <span className="c-black">
            {"on different medical topics, ensuring you stay ahead "}
          </span>
          {"in your field."}
        </span>
      ),
      backColor: "linear-gradient(360deg, #FF8800 0%, #FF7FBD 100%)",
      backImage: {
        src: bgSlideEn2,
        className: "fade-slide-left opacity-50",
      },
      image: { src: mainImageSlideEn2, className: "fade-slide-up" },
    },
    {
      title: (
        <span className="c-black">
          <span className="c-orange">{"AI-Gen"}</span>
          {" Videos 🎥"}
        </span>
      ),
      text: (
        <span className="c-grey">
          {"We turn automatically lengthy "}
          <span className="c-black">
            {".pdf publications into engaging short-form videos, "}
          </span>
          {
            "offering you a unique and refreshing way to interact with scientific literature."
          }
        </span>
      ),
      backColor:
        "linear-gradient(351.52deg, rgba(210, 220, 226, 0.4) -4.64%, rgba(236, 240, 245, 0.4) 72.85%, rgba(252, 196, 8, 0.4) 149.37%),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
      backImage: {
        src: bgSlideEn3_1,
        className: "fade-slide-up slide-3-left",
      },
      backImage2: {
        src: bgSlideEn3_2,
        className: "fade-slide-up slide-3-right",
      },
      image: {
        src: mainImageSlideEn3,
        className: "fade-slide-up small-img animation-forwards",
      },
      custom: <CustomVideo2Pdf />,
    },
  ],
  [SupportedLanguagesEnum.FR]: [
    {
      title: (
        <span className="c-white">
          {"Découvrez "}
          <span className="c-yellow">{"JuisciGPT"}</span>
          {" ✨"}
        </span>
      ),
      text: (
        <span className="c-white">
          <span className="c-lightgrey">{"pour obtenir "}</span>
          {
            "des réponses fondées sur des preuves scientifiques, reliées directement à leurs sources "
          }
          <span className="c-lightgrey">
            {"- à toutes questions médicales"}
          </span>
        </span>
      ),
      backColor: "linear-gradient(225deg, #212121 0%, #4C5861 100%)",
      image: { src: mainImageSlideFr1, className: "fade-slide-up" },
    },
    {
      title: (
        <span>
          <span className="c-white">{"Nectars"}</span>
          {" de Science 🧃"}
        </span>
      ),
      text: (
        <span className="c-white">
          {"Notre IA génère automatiquement "}
          {"des revues de la littérature - "}
          <span className="c-black">
            {"des résumés de résumés - élaborés à partir "}
          </span>
          {"de dizaines de milliers de publications "}
          <span className="c-black">
            {
              "sur différents sujets médicaux, afin de t'aider à rester à la pointe "
            }
          </span>
          {"dans ton domaine."}
        </span>
      ),
      backColor: "linear-gradient(360deg, #FF8800 0%, #FF7FBD 100%)",
      backImage: {
        src: bgSlideFr2,
        className: "fade-slide-left opacity-50",
      },
      image: { src: mainImageSlideFr2, className: "fade-slide-up" },
    },
    {
      title: (
        <span className="c-black">
          {"Vidéos "}
          <span className="c-orange">{"AI-Gen 🎥"}</span>
        </span>
      ),
      text: (
        <span className="c-grey">
          {"Nous transformerons automatiquement "}
          <span className="c-black">
            {"de longues publications en vidéos captivantes, "}
          </span>
          {
            "pour t'offrir une manière unique et rafraîchissante d'interagir avec la littérature scientifique."
          }
        </span>
      ),
      backColor:
        "linear-gradient(351.52deg, rgba(210, 220, 226, 0.4) -4.64%, rgba(236, 240, 245, 0.4) 72.85%, rgba(252, 196, 8, 0.4) 149.37%),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
      backImage: {
        src: bgSlideFr3_1,
        className: "fade-slide-up slide-3-left",
      },
      backImage2: {
        src: bgSlideFr3_2,
        className: "fade-slide-up slide-3-right",
      },
      image: {
        src: mainImageSlideFr3,
        className: "fade-slide-up small-img animation-forwards",
      },
      custom: <CustomVideo2Pdf />,
    },
  ],
};
