import { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ArticleModel } from "../../domains/article/article.types";
import { JournalModel } from "../../domains/journal/journal.types";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import styled, { keyframes } from "styled-components";
import ArticleCard from "../../components/cards/ArticleCard";
import { HeaderBoxAutoSafe, HeaderLinks, NavBack, NavLang } from "../../components/app/headers/layout";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";
import { useLazyGetArticlesListQuery } from "../../domains/article/endpoints/getArticlesList";
import { getJournalByUid } from "../../domains/journal/endpoints/getJournalByUid";
import { displayToast } from "../../components/app/AppToast";
import Loader from "../../components/Loader";
import CustomIcon from "../../components/CustomIcon";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import InfiniteScroll from "react-infinite-scroll-component";
import disableScroll from "disable-scroll";
import { useJournalFollow } from "../../domains/journal/hooks/useJournalFollow";
import ExpandableText from "../../components/app/ExpandableText";

const maxScroll = 10;

export default function JournalPage() {
  const history = useHistory();
  const { uid } = useParams<{ uid: string }>();
  const { activeLang, t } = useLangContext();
  const { currentUser } = useCurrentUser();
  const navbarWrapperRef = useRef<HTMLDivElement>(null);
  const [journal, setJournal] = useState<JournalModel | null>(null);
  const [articles, setArticles] = useState<ArticleModel[]>([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [isBackgroundExpanded, setBackgroundExpanded] = useState(false);
  const [total, setTotal] = useState(0);
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const [getArticlesList] = useLazyGetArticlesListQuery();
  const { isFollowed, toggleFollow } = useJournalFollow(journal);

  useEffect(() => {
    disableScroll.off();
    window.scrollTo(0, 0);

    initialize();
  }, [uid]);

  useEffect(() => {
    if (journal) {
      scrollTo(0, 0);
      fetchArticles(true);
    }
  }, [activeLang]);

  async function initialize() {
    try {
      // Getting journal information
      const journal = await getJournalByUid(uid);
      setJournal(journal);

      // Getting articles related to the journal
      fetchArticles(true);

      // Getting user followed journals list
      const isAuthentified = await isUserAuthentified();
      if (isAuthentified && !currentUser) await getCurrentUser();

      // Listen to scroll on page
      listenToScrollEvent();
    } catch (error) {
      console.error("Couldn't mount journal page:", error);
      displayToast(t("error:default"));
      history.replace("/discovery");
    }
  }

  async function fetchArticles(resetList = false) {
    try {
      const { docs, total } = await getArticlesList({
        offset: resetList ? 0 : articles.length,
        limit: 10,
        language: activeLang,
        journal: uid,
      }).unwrap();

      setTotal(total);
      setArticles((prevArticles) =>
        prevArticles.length && !resetList ? [...prevArticles, ...docs] : docs
      );
      setLoading(false);
    } catch (error: any) {
      console.error("Couldn't fetch articles.", error);
      displayToast(t("error:default"));
      history.replace("/discovery");
    }
  }

  function listenToScrollEvent() {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        calculateScrollDistance();
      });
    });
  }

  function calculateScrollDistance() {
    const scrollTop = window.scrollY; // how much the user has scrolled by
    const winHeight = window.innerHeight;
    const docHeight = getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    const scrollPosition = Math.floor((scrollTop / totalDocScrollLength) * 100);

    setScrollPosition(scrollPosition);
  }

  function getDocHeight() {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  }

  return (
    <>
      <div ref={navbarWrapperRef}>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack /></HeaderLinks>
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxAutoSafe>
      </div>

      {isLoading ? <Loader /> : (
        <>
          {scrollPosition > maxScroll && articles?.length && (
            <FixedInfos
              style={{ top: navbarWrapperRef.current?.clientHeight }}
              className={scrollPosition > maxScroll ? "fixed-infos" : ""}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {journal?.image ? (
                  <JournalLogo src={journal?.image?.url} alt={journal?.name} />
                ) : (
                  <FallbackJournalName>{journal?.name}</FallbackJournalName>
                )}
                <FollowButton
                  className={isFollowed ? "followed-journal" : ""}
                  onClick={toggleFollow}
                >
                  {isFollowed ? t("common:action.unfollow") : t("common:action.follow")}
                </FollowButton>
              </div>
              <CustomIcon
                onClick={() => history.goBack()}
                iconName="close_alt"
              />
            </FixedInfos>
          )}

          <div style={{ padding: 30, paddingTop: 48 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 17,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {journal?.image ? (
                  <JournalLogo src={journal?.image?.url} alt={journal?.name} />
                ) : (
                  <FallbackJournalName>{journal?.name}</FallbackJournalName>
                )}
                <FollowButton
                  className={isFollowed ? "followed-journal" : ""}
                  onClick={toggleFollow}
                >
                  {isFollowed ? t("common:action.unfollow") : t("common:action.follow")}
                </FollowButton>
              </div>
              <CustomIcon
                onClick={() => history.goBack()}
                iconName="close_alt"
              />
            </div>

            <ExpandableText
              isExpanded={isBackgroundExpanded}
              setExpand={setBackgroundExpanded}
            >
              <JournalDescription>{journal?.description}</JournalDescription>
            </ExpandableText>

            <MetricBadgeWrapper>
              <MetricBadge
                value={journal?.impact_factor}
                text="impact factor"
              />
              <MetricBadge value={journal?.h_index} text="h-index" />
              <MetricBadge value={journal?.sjr} text="sjr" />
            </MetricBadgeWrapper>
          </div>

          <ArticleWrapper>
            <InfiniteScroll
              dataLength={articles.length}
              next={() => fetchArticles()}
              hasMore={articles.length < total}
              loader={<Loader loaderOnly />}
            >
              <CardList>
                {articles.map((article: ArticleModel) => {
                  return (
                    <ArticleCard
                      key={article._id}
                      article={article}
                      analyticsListName={AnalyticsItemList.JOURNAL_PAGE}
                    />
                  );
                })}
              </CardList>
            </InfiniteScroll>
            {!articles?.length && (
              <NoArticles>
                <p>{t("There is no articles for this journal")}</p>
              </NoArticles>
            )}
          </ArticleWrapper>
        </>
      )}
    </>
  );
}

const MetricBadge = (props: any) => {
  let color = "#FFC408";
  let bgColor = "#FFC408";

  switch (props.text) {
    case "impact factor":
      color = "#212121";
      bgColor = "#FFC408";
      break;
    case "h-index":
      color = "#FFFFFF";
      bgColor = "#90A4AE";
      break;
    case "sjr":
      color = "#FFFFFF";
      bgColor = "#4C5861";
      break;
  }

  function formatText(text: string) {
    return text.split(" ").join("\n");
  }

  return (
    <div
      style={{
        height: 50,
        width: 50,
        borderRadius: "200px",
        padding: "18px",
        background: bgColor,
        position: "relative",
        boxShadow: "2px 2px 4px rgba(33, 33, 33, 0.1)",
        marginRight: 8,
        display: props.value > 0 ? "block" : "none",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <div
          style={{
            color: color,
            fontFamily: "Inter",
            fontWeight: 700,
            textTransform: "uppercase",
            letterSpacing: "0.02em",
            textAlign: "center",
            fontSize: "8px",
            lineHeight: "8px",
            whiteSpace: "pre",
          }}
        >
          {formatText(props.text)}
        </div>
        <div
          style={{
            color: color,
            fontFamily: "Oswald",
            fontWeight: 700,
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {props.value}
        </div>
      </div>
    </div>
  );
};

const slideTop = keyframes`
    from {
        top: 0;
    }
    to {
        top: 53px;
    }
`;

const FixedInfos = styled.div`
  z-index: 3;
  position: fixed;
  background: #f2f2f2;
  width: 100%;
  padding: 15px 30px;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
  animation: ${slideTop} 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const JournalLogo = styled.img`
  height: 35px;
  width: auto;
  max-width: 100px;
  object-fit: contain;
`;

const FollowButton = styled.button`
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 700;
  color: #212121;
  background: #ffc408;
  border-radius: 100px;
  margin-left: 10px;
  max-width: 150px;

  &.followed-journal {
    background: #fff3ce;
    color: #ffc408;
  }
`;

const FallbackJournalName = styled.span`
  font-weight: 600;
  font-family: "Inter";
  line-height: 110%;
  display: table;
  max-width: 50%;
`;

const JournalDescription = styled.span`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  color: #212121;
`;

const MetricBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0 -50px 0;
`;

const CardList = styled.div`
  padding: 16px 8px calc(16px + var(--safe-area-top));
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const NoArticles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;

  p {
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter";
    color: #fff;
  }
`;

const ArticleWrapper = styled.div`
  background: linear-gradient(
    180deg,
    #ff699c 0%,
    #fe5763 26.99%,
    #ff8a00 51.99%,
    #fdb955 77.51%,
    #ffc408 100%
  );

  min-height: ${window.innerHeight * 0.7}px;
  max-height: 100%;
  width: 100%;
  overflow-y: hidden;
  padding-top: 10px;
`;
