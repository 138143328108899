import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { store, useAppDispatch, useAppSelector } from "../../redux";
import { SearchCategory } from "../../domains/search/search.types";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";
import { setSearchPageScrollPosition } from "../../domains/search/search.reducer";
import { searchWithDebounce } from "../../domains/search/utils/searchWithDebounce";
import { gaEventSearch, gaEventSearchSelectSection } from "../../tools/analytics/searchAnalytics";
import { getTotalSearchResultsCount } from "../../domains/search/utils/getTotalSearchResultsCount";
import { smoothScrollToTop } from "../../tools/utils";
import { HeaderBoxAutoSafe, HeaderLinks, NavBack, NavLang } from "../../components/app/headers/layout";
import BottomNavbar from "../../components/app/BottomNavbar";
import { SearchPageContent, SearchPageHeader, SearchPageInput, SearchPageWrapper, SearchResultsSection } from "../../components/search/layout";
import SearchFiltersPanel from "../../components/search/SearchFiltersPanel";
import SearchResultsArticles from "../../components/search/SearchResultsArticles";
import SearchResultsJournals from "../../components/search/SearchResultsJournals";
import SearchResultsPlaylists from "../../components/search/SearchResultsPlaylists";
import SearchResultsUsers from "../../components/search/SearchResultsUsers";
import SearchResultsVideos from "../../components/search/SearchResultsVideos";
import SearchResultsNectars from "../../components/search/SearchResultsNectars";
import FilterIconButton from "../../components/filters/FilterIconButton";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";
import disableScroll from "disable-scroll";

const { ALL, ARTICLES, JOURNALS, PLAYLISTS, USERS, VIDEOS, NECTARS } = SearchCategory;

export default function CategorySearchPage() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ category: SearchCategory }>();
  const searchValueParam = new URLSearchParams(location.search).get("value") ?? "";
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const filtersApplied = useAppSelector((state) => state.search.filtersApplied);
  const [isFiltersPanelOpen, setFiltersPanelOpen] = useState(false);
  const [isBadgeSelected, setBadgeSelected] = useState(false);
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    (async function () {
      try {
        // NOTE: Necessary post login (no onboarding check to load data).
        if (user.isUninitialized && await isUserAuthentified()) {
          await getCurrentUser();
        }

        disableScroll.off();

        gaEventSearchSelectSection();

        setTimeout(() => { // NOTE: delay to let results load first
          // NOTE: Usual selector not reliable enough, using store state instead.
          const searchPageScrollPosition = store.getState().search.searchPageScrollPosition;
          contentRef.current?.scrollTo({ top: searchPageScrollPosition });
        }, 0);
      } catch (error) {
        console.error("Couldn't mount category search page.", error);
        displayToast(t("error:default"));
        history.replace("/discovery");
      }
    })();
  }, []);

  function handleScrollPage() {
    if (contentRef.current) {
      dispatch(setSearchPageScrollPosition(contentRef.current.scrollTop));
    }
  }

  // Handle Backspace on input
  function handleKeydown(key: KeyboardEvent) {
    if (key.code === "Backspace" && searchValueParam.length === 0) {
      if (!isBadgeSelected) {
        setBadgeSelected(true);
      } else {
        handleNavigateBack();
      }
    }
  }

  function handleNavigateBack() {
    history.replace(`/search`);
  }

  return (
    <>
      <SearchPageWrapper>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack onClick={handleNavigateBack} /></HeaderLinks>
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxAutoSafe>

        <SearchPageHeader>
          <div className="search-input-wrapper" onTouchMove={(e) => e.preventDefault()}>
            <SearchPageInput
              inputRef={inputRef}
              autoFocus
              value={searchValueParam}
              onClear={() => {
                searchWithDebounce("", () => {
                  history.push(`/search/${params.category}`);
                  smoothScrollToTop(contentRef.current);
                });
                inputRef.current?.focus();
              }}
              onSearch={(value: string) => {
                searchWithDebounce(value, async () => {
                  setBadgeSelected(false);
                  history.push(`/search/${params.category}?value=${value}`);
                  smoothScrollToTop(contentRef.current);

                  const totalResultsCount = await getTotalSearchResultsCount();
                  gaEventSearch(
                    value,
                    totalResultsCount,
                    params.category,
                  );
                });
              }}
              prefix={{
                [ALL]: "",
                [ARTICLES]: t("Articles"),
                [JOURNALS]: t("Journals"),
                [PLAYLISTS]: t("Playlists"),
                [USERS]: t("Users"),
                [VIDEOS]: t("Videos"),
                [NECTARS]: t("Nectars"),
              }[params.category] ?? ""}
              isNectar={params.category === NECTARS}
              entrySelected={isBadgeSelected}
              onPrefixClick={handleNavigateBack}
              onCancel={handleNavigateBack}
              onKeyDown={handleKeydown}
            />

            {[ARTICLES, JOURNALS, VIDEOS, NECTARS].includes(params.category) &&
              <FilterIconButton
                active={filtersApplied}
                label={t("search:filter.title")}
                onClick={() => setFiltersPanelOpen(true)}
              />
            }
          </div>
        </SearchPageHeader>

        <SearchPageContent>
          <div
            ref={contentRef}
            id="content-ref"
            onScroll={handleScrollPage}
          >
            <SearchResultsSection>
              <h3>{{
                [ALL]: "",
                [ARTICLES]: t("Publications"),
                [JOURNALS]: t("Journals"),
                [PLAYLISTS]: t("Playlists"),
                [USERS]: t("Users"),
                [VIDEOS]: t("Videos"),
                [NECTARS]: t("Nectars of Science"),
              }[params.category] ?? ""}</h3>
              {params.category === ARTICLES && <SearchResultsArticles />}
              {params.category === JOURNALS && <SearchResultsJournals />}
              {params.category === PLAYLISTS && <SearchResultsPlaylists />}
              {params.category === USERS && <SearchResultsUsers />}
              {params.category === VIDEOS && <SearchResultsVideos />}
              {params.category === NECTARS && <SearchResultsNectars />}
            </SearchResultsSection>
          </div>
        </SearchPageContent>

        <BottomNavbar />
      </SearchPageWrapper>

      {isFiltersPanelOpen && (
        <SearchFiltersPanel
          currentContext={params.category}
          onClose={() => setFiltersPanelOpen(false)}
        />
      )}
    </>
  );
}
