import { IImage, SupportedLanguagesEnum } from "../../interfaces";
import { IContent } from "../../interfaces/content.interface";
import { SpecialtyModel } from "../app/app.types";
import { ArticleModel } from "../article/article.types";
import { DefaultProfilePicture } from "../image/image.types";
import { JournalModel } from "../journal/journal.types";
import { OrganisationModel } from "../organisation/organisation.types";
import { PlaylistModel } from "../playlist/playlist.types";
import { ProfessionModel } from "../profession/profession.types";

// NOTE: Values are shared with the backend. Do not change.
export enum LemonCertifiedRequestStatus {
  Waiting = "Waiting",
  Rejected = "Rejected",
  Approved = "Approved",
}

// NOTE: Values are shared with the backend. Do not change.
export enum UserRoomType {
  DEFAULT = "Default",
  AUDIO = "Audio",
}

export interface UserModel {
  _id: string;
  uid: string;
  email: string;
  country: string;
  firstname: string;
  lastname: string;
  fullname: string;
  birthDate: string;
  profession: ProfessionModel;
  main_specialty: SpecialtyModel;
  medical_specialties?: SpecialtyModel[];
  username: string; // TODO: Check if used?
  language: SupportedLanguagesEnum;
  image: IImage;
  profilePicture: {
    name: DefaultProfilePicture;
    url: string;
    path: string;
  };
  meta: {
    creationDate: Date;
    lastModified: Date;
    lastLogin: Date;
    firstLogin: boolean;
  };
  organisations: OrganisationModel[];
  rooms: string[];
  device?: string;
  savedContent: IContent[];
  likes: ArticleModel[];
  likedContent: IContent[];
  lemonCertified: boolean;
  link?: string;
  bio?: string;
  awards?: Array<{
    _id: string;
    label: string;
    content: string;
  }>;
  journals?: JournalModel[];
}

export interface UserConnectionRequestModel {
  id: string;
  fromUserId: string;
  toUserId: string;
  fromUser: UserModel;
  toUser: UserModel;
}

export interface UserRoomModel {
  _id: string;
  type: UserRoomType;
  isPublic: boolean;
  name: string;
  translations: { [key in SupportedLanguagesEnum]: any };
  organisation: OrganisationModel;
  primaryColor: string;
  contents: any[]; // TODO: Check type.
  playlists: string[];
  allowedLanguages: SupportedLanguagesEnum[];
  logo: {
    path: string;
    url: string;
  };
  ico: {
    path: string;
    url: string;
  };
}
